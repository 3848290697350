import "./Subscribe.scss";
import { useEffect, useState } from "react";

import emailjs from "@emailjs/browser";

function Subscribe() {
  const [fromName, setFromName] = useState("");
  const [fromEmail, setFromEmail] = useState("");
  const [company, setCompany] = useState("");

  const [fromNameError, setFromNameError] = useState(false);
  const [fromEmailError, setFromEmailError] = useState(false);
  const [companyError, setCompanyError] = useState(false);
  const [formError, setFormError] = useState(false);

  const [subSuccessful, setSubSuccessful] = useState(false);

  const sendForm = async (e) => {
    e.preventDefault(e);

    if (fromName.length <= 2) {
      setFromNameError(true);
      return;
    }

    if (company.length <= 2) {
      setCompanyError(true);
      return;
    }

    if (fromEmail.length <= 3 || !fromEmail.includes("@")) {
      setFromEmailError(true);
      return;
    } else {
      var templateParams = {
        from_name: fromName,
        from_email: fromEmail,
        company: company,
      };
      emailjs
        .send(
          "service_roa7vzc",
          "eacc-subscription",
          templateParams,
          "QdeLXIZHaJh4tGVTM"
        )
        .then(
          function (response) {
            console.log("SUCCESS!", response.status, response.text);
          },
          function (error) {
            console.log("FAILED...", error);
          }
        );
    }
    // alert("Email successfully sent");
    setSubSuccessful(true);
  };

  return (
    <div className="subscribe">
      <div className="subscribe__text-wrapper">
        <div className="subscribe__divider"></div>
        <h3 className="subscribe__title">Subscribe</h3>
        <p className="subscribe__text">
          Please send me industry related information from the Environmental
          Abatement Council of Canada, such as events, news, alerts, new
          guidelines, etc.
        </p>
      </div>
      {subSuccessful ? (
        <div className="subscribe__success">
          <h2 className="subscribe__message">Thank you for subscribing!</h2>
        </div>
      ) : (
        <form className="subscribe__form" id="contact-form" onSubmit={sendForm}>
          <input
            className={
              fromNameError
                ? "subscribe__input subscribe__input--error"
                : "subscribe__input"
            }
            type="name"
            name="from_name"
            value={fromName}
            placeholder="Name"
            onChange={(e) => setFromName(e.target.value)}
          />
          <input
            className={
              companyError
                ? "subscribe__input subscribe__input--error"
                : "subscribe__input "
            }
            type="text"
            name="company"
            placeholder="Company"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
          />
          <input
            className={
              fromEmailError
                ? "subscribe__input subscribe__input--error"
                : "subscribe__input"
            }
            type="text"
            name="user_email"
            onChange={(e) => setFromEmail(e.target.value)}
            value={fromEmail}
            placeholder="Email Address"
          />
          <button className=" subscribe__button" type="submit">
            SUBSCRIBE
          </button>
        </form>
      )}
    </div>
  );
}
export default Subscribe;
