export default [
  {
    subtitle: "2021 EACC Annual General Meeting",
    info: (
      <div className="single-gallery__info">
        <p className="single-gallery__text">
          On June 10th, 2021 the Environmental Abatement of Ontario (EACO) held its Annual General Meeting where it announced its new name: the
          Environmental Abatement Council of Canada (EACC). <a>Read the press release here.</a>
        </p>
        <p className="single-gallery__text">The 2021 – 2022 Board of Directors was announced:</p>
        <ul className="single-gallery__list">
          <li className="single-gallery__list-item">John Kocjan, ECOH</li>
          <li className="single-gallery__list-item">Sarah Jamieson, EMSL</li>
          <li className="single-gallery__list-item">Mark Reinhardt, QM Environmental</li>
          <li className="single-gallery__list-item">Steve Fulford, BGIS</li>
          <li className="single-gallery__list-item">Ian Henderson, H & S Specialties</li>
          <li className="single-gallery__list-item">Peter Ferrante, Ferro Canada Inc.</li>
          <li className="single-gallery__list-item">Martin Ling, Stantec</li>
          <li className="single-gallery__list-item">Kelly Smith, ARCADIS Canada Inc.</li>
          <li className="single-gallery__list-item">Jeff Lainsbury, Reveal Environmental</li>
          <li className="single-gallery__list-item">Glenn Smith, Safetech Environmental</li>
          <li className="single-gallery__list-item"> Norine Jones, Power Vac Services</li>
          <li className="single-gallery__list-item">David Bremner, Environmental Response Team</li>
          <li className="single-gallery__list-item">Jim Anderson, GB Environmental</li>
        </ul>
        <p className="single-gallery__text">A special thanks to the AGM sponsors which provided a Starbucks gift card to all attendees.</p>
      </div>
    ),
    photos: [
      {
        photo: "/images/PhotoGalleries/2021/2021-eacc-agm/1.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2021/2021-eacc-agm/2.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2021/2021-eacc-agm/3.jpg",
      },
    ],
  },
];
