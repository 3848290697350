import React from "react";
import "../../pages/DocumentsPage/DocumentsPage.scss";
import Hero from "../../components/Hero/Hero";
import { documents } from "../../documents";

function DocumentsPage() {
  return (
    <>
      <Hero title={"Documents"} />
      <div className="documents">
        <div className="documents__main">
          <p className="documents__subheader">Documents available for download</p>
        </div>

        {documents.map((doc, i) => {
          return (
            <div className="documents__section">
              <div className="documents__top--bin">
                <p className="documents__title">
                  {doc.title}
                  {doc.website ? (
                    <a
                      className="documents__link"
                      href="https://www.csagroup.org/article/research/asbestos-management-in-canada-assessing-the-need-for-a-national-standard/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      website.
                    </a>
                  ) : (
                    ""
                  )}
                </p>
                <p className={`documents__info ${doc.info === "Introduction:" ? "documents__info--padding" : ""}`}>{doc.info}</p>
                {doc.bullets && (
                  <ul className="documents__list">
                    {doc.bullets.map((bullet) => (
                      <li className="documents__list-item">{bullet}</li>
                    ))}
                  </ul>
                )}
                <p className="documents__date">{doc.date}</p>
              </div>

              {i !== 1 && (
                <div className="documents__bottom--bin">
                  <a className="documents__link" href={doc.link} download>
                    <button className="documents__button">Download</button>
                  </a>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div className="documents__safety">
        <h2 className="documents__header">Asbestos Safety</h2>
        <p className="documents__text">
          Construction professionals must take safety precautions when working with hazardous materials, and asbestos, with its numerous health risks,
          should be at the top of the list.
        </p>
        <br></br>
        <p className="documents__text">
          “It’s quite deadly,” says Dr. Kapil Khatter, president of the Canadian Association of Physicians for the Environment. “It gets in your
          lungs, hangs around and can (eventually) create inflammation, lung cancer, mesothelioma, etc.”
        </p>
        <br></br>
        <p>How it’s dangerous</p>
        <p className="documents__text">
          Christelle Legault, media relations officer for Health Canada, says asbestos is the generic name for various fibrous materials found
          naturally in rock formations around the world.
        </p>
        <br></br>
        <p className="documents__text">
          “Because it is a valuable reinforcing, insulating and fire-proofing material, asbestos was used widely in construction materials such as
          insulation board, asbestos cement, drywall joint cement, spackling, and floor and ceiling tiles,” she says.
        </p>
        <br></br>
        <p className="documents__text">According to Legault, health risk caused by asbestos exposure depends on the following factors:</p>
        <br></br>
        <ul className="documents__list">
          <li className="documents__list-item">The concentration of asbestos fibers in the air </li>
          <li className="documents__list-item">How long the exposure lasted</li>
          <li className="documents__list-item">How often you were exposed </li>
          <li className="documents__list-item">The size of the asbestos fibers inhaled </li>
          <li className="documents__list-item">The amount of time since the initial exposure</li>
        </ul>
        <br></br>
        <p className="documents__text">
          Legault stresses that asbestos only poses health risks when fibers are present in the air we breathe, and products do not release
          significant amounts of fiber unless they are cut or damaged. If the asbestos fibers are enclosed or tightly bound in a compound, there is
          not a significant health risk.
        </p>
        <br></br>
        <p className="documents__text">
          Unfortunately, one of the major problems is that until the 1970s, sprayed or friable (easily broken up) asbestos was used in buildings.
          Because of this history, Legault recommends that people working in construction, maintenance and renovation of older buildings should be
          especially careful when handling asbestos.
        </p>
        <br></br>
        <p className="documents__text">Tips For Dealing with Asbestos</p>

        <p className="documents__text">
          <strong>Learn when the building you’re working on was built: </strong>
          Steven Fulford, president of the Environmental Abatement Council of Ontario (EACO), says that though now asbestos is heavily regulated in
          Canada, there were very few restrictions before 1986. Most manufacturers began to stop putting it in insulating products in the early 1980s
          and in non-friable products in the late 1980s and early ’90s, so if your building was built before or during this time, take extra
          precaution.
        </p>
        <br></br>

        <p className="documents__text">
          <strong>Determine whether asbestos is present before beginning work: </strong>
          Prior to starting any repair, renovation or demolition project, Fulford says you must do a hazardous material assessment. He says most
          occupational safety acts require it to determine what products contain asbestos, which can include any of the following: floor tiles, glue,
          drywall, plaster, caulking, pipe or duct installation, and vibration gaskets. Avoid creating asbestos dust by scraping, brushing, rubbing or
          cutting damaged insulation:Legault says insulation damage should be reported to the appropriate authority, such as the Occupational Health
          and Safety Manager.
        </p>
        <br></br>
        <p className="documents__text">
          <strong>Learn your province’s specific procedures for handling asbestos: </strong>
          Contractors should always follow all regulations and requirements established by their respective occupational health and safety
          authorities, as it is a legal responsibility for contractors and a personal safety issue for workers, Legault says.
          <br></br>
          <br></br>
          Fulford says most procedures are very similar, but some provinces require special training to deal with asbestos. He says searching the
          keyword “asbestos” on your provincial government’s website should easily lead you to the correct information.
        </p>
        <br></br>
        <p className="documents__text">
          <strong>Be proactive about your health: </strong>
          Dr. Khatter says that unfortunately, illness caused by asbestos exposure usually doesn’t show any symptoms until there’s a serious problem.
          He recommends getting regular lung X-rays sometimes as much as once a year to monitor your health and catch any illness at its earliest
          stage. Your regular physician might suffice, but a respirologist or someone who does occupational medicine might be better versed in the
          risks of asbestos exposure. Look into the help of occupational clinics, he suggests, because they’re independent of companies as they don’t
          have to worry about liability. For example, those living in Ontario can find a clinic nearest them by visiting the website of Occupational
          Health Clinics for Ontario Workers, Inc.
          <br></br>
          <br></br>
          <i> For Additional Research</i>
          <br></br>
          Educate yourself about the health risks of asbestos so that you and your workers know you’re taking every precaution. Legault says for more
          information on asbestos, visit Health Canada’s website. For specific information on safety precautions and acceptable respirator masks when
          working with asbestos, contact the Canadian Centre for Occupational Health and Safety (CCOHS) through its website or by phone:
          1-800-263-8466.
        </p>
      </div>
    </>
  );
}

export default DocumentsPage;
