export default [
  {
    subtitle: "2015 EACC Golf Tournament",
    photos: [
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-golf-tournament/2015-Golf-Tournament-01.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-golf-tournament/2015-Golf-Tournament-02.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-golf-tournament/2015-Golf-Tournament-03.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-golf-tournament/2015-Golf-Tournament-04.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-golf-tournament/2015-Golf-Tournament-05.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-golf-tournament/2015-Golf-Tournament-06.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-golf-tournament/2015-Golf-Tournament-07.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-golf-tournament/2015-Golf-Tournament-08.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-golf-tournament/2015-Golf-Tournament-09.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-golf-tournament/2015-Golf-Tournament-10.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-golf-tournament/2015-Golf-Tournament-11.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-golf-tournament/2015-Golf-Tournament-12.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-golf-tournament/2015-Golf-Tournament-13.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-golf-tournament/2015-Golf-Tournament-14.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-golf-tournament/2015-Golf-Tournament-15.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-golf-tournament/2015-Golf-Tournament-16.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-golf-tournament/2015-Golf-Tournament-17.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-golf-tournament/2015-Golf-Tournament-18.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-golf-tournament/2015-Golf-Tournament-19.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-golf-tournament/2015-Golf-Tournament-20.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-golf-tournament/2015-Golf-Tournament-21.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-golf-tournament/2015-Golf-Tournament-22.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-golf-tournament/2015-Golf-Tournament-23.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-golf-tournament/2015-Golf-Tournament-24.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-golf-tournament/2015-Golf-Tournament-25.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-golf-tournament/2015-Golf-Tournament-26.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-golf-tournament/2015-Golf-Tournament-27.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-golf-tournament/2015-Golf-Tournament-28.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-golf-tournament/2015-Golf-Tournament-29.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-golf-tournament/2015-Golf-Tournament-30.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-golf-tournament/2015-Golf-Tournament-31.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-golf-tournament/2015-Golf-Tournament-32.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-golf-tournament/2015-Golf-Tournament-33.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-golf-tournament/2015-Golf-Tournament-34.jpg",
      },
    ],
  },
];
