import React from "react";
import "../AboutUsPage/AboutUsPage.scss";
import Hero from "../../components/Hero/Hero";

function AboutUsPage() {
  return (
    <>
      <Hero title={"Who We Are"} />
      <div className="about">
        <div className="about__box">
          <div className="about__line"></div>
          <h2 className="about__title">About EACC</h2>
          <p className="about__text">
            The Environmental Abatement Council of Canada (EACC) is a contractor based organization formed by professionals within the environmental
            abatement industry.
          </p>
          <br></br>
          <p className="about__text">
            Our members represent our industry as a whole including contractors, consultants, engineers suppliers, government officials and others
            that have an interest in the environmental abatement industry.
          </p>
          <br></br>
          <p className="about__text">
            If you are looking for a qualified list of Abatement Contractors and/or Environmental Consultants for your upcoming projects for Asbestos,
            Mould, Lead or any other Environmental projects, all EACC members carry Environmental and Pollution Insurance Coverage.
          </p>
          <br></br>
          <p className="about__text">
            EACC provides important input to the Ontario Ministry of Labour on Environmental Abatement matters and Guidelines.
          </p>
        </div>
        <div className="about__box about__box--mission">
          <div className="about__line"></div>
          <h2 className="about__title">Our Mission</h2>
          <p className="about__text">
            The betterment and promotion in the public interest of the environmental abatement and hazardous materials industry.
          </p>
          <br></br>
          <p className="about__text">To promote the highest standards of conduct among our members.</p>
          <br></br>
          <p className="about__text">
            To collect and disseminate information regarding the management of hazardous materials including regulations from all provinces and
            territories.
          </p>
          <br></br>
          <p className="about__text">
            To collect and disseminate information regarding the health effects associated with exposure to specific hazardous materials.
          </p>
        </div>
      </div>
    </>
  );
}

export default AboutUsPage;
