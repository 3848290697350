export default [
  {
    info: (
      <div className="single-gallery__info">
        <p className="single-gallery__text">September 20, 2005</p>
        <p className="single-gallery__text">
          At the recent <strong>Environmental Abatement Council of Canada</strong> golf tournament held on Tuesday, September 20, 2005 at Caledon
          Woods Golf Club, fifty-six persons enjoyed a great day on the links. All enjoyed a BBQ lunch on the patio, sponsored by I & I Construction
          Services. Members then spent the afternoon on the course, followed by cocktails and dinner. Pinchin Environmental sponsored the dinner wine.
          All golfers received a golf club umbrella and hat, compliments of EACC and a hat, compliments of Caledon Woods.
        </p>
        <p className="single-gallery__text">
          Thanks are extended to the Golf Committee – Ian Henderson of Hazmasters and Steve Fulford of Jacques Whitford, for their assistance in
          organizing the event. As well, thanks are extended to those who sponsored holes – Hazmasters, Inscan, Ontario Insulation, Wayne and Harold
          Smith Construction and Tri-Phase Environmental – and to all who attended.
        </p>

        <p className="single-gallery__text">
          Don Pinchin, President of EACC, acted as Master of Ceremonies for the evening. He gave a special welcome to Jim Gardner and Evelyn Stefov
          from the Ministry of Labour. As well, he provided a brief overview of EACC activities.
        </p>

        <p className="single-gallery__text">
          <u> Winners included</u>:
        </p>
        <p className="single-gallery__text single-gallery__text--bold">
          Low Team: Team Tri-Phase – Adam, Marcus, Mark, Tim
          <br />
          Closest to the Pin: Fred Atrach
          <br />
          Longest Drive: Jeff Dohert
          <br />
          Longest Putt (Hole 7): Mark Aikman
          <br />
          Longest Putt (Hole 15): Adam Roney
          <br />
          Winner of the Grand Prize – 27” TV: Jason Smith
        </p>
      </div>
    ),
    subtitle: "2005 EACC Golf Tournament",
    photos: [
      {
        photo: "/images/PhotoGalleries/2005/2005-eacc-golf-tournament/2005-Golf-Tournament-01.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2005/2005-eacc-golf-tournament/2005-Golf-Tournament-02.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2005/2005-eacc-golf-tournament/2005-Golf-Tournament-03.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2005/2005-eacc-golf-tournament/2005-Golf-Tournament-04.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2005/2005-eacc-golf-tournament/2005-Golf-Tournament-05.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2005/2005-eacc-golf-tournament/2005-Golf-Tournament-06.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2005/2005-eacc-golf-tournament/2005-Golf-Tournament-07.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2005/2005-eacc-golf-tournament/2005-Golf-Tournament-08.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2005/2005-eacc-golf-tournament/2005-Golf-Tournament-09.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2005/2005-eacc-golf-tournament/2005-Golf-Tournament-10.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2005/2005-eacc-golf-tournament/2005-Golf-Tournament-11.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2005/2005-eacc-golf-tournament/2005-Golf-Tournament-12.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2005/2005-eacc-golf-tournament/2005-Golf-Tournament-13.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2005/2005-eacc-golf-tournament/2005-Golf-Tournament-14.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2005/2005-eacc-golf-tournament/2005-Golf-Tournament-15.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2005/2005-eacc-golf-tournament/2005-Golf-Tournament-16.jpg",
      },
    ],
  },
];
