import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Hero from "../components/Hero/Hero";
import anime from "animejs";
import { v4 as uuidv4 } from "uuid";
import { doc, setDoc } from "firebase/firestore";
import { database } from "../firebase";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 0;
  h3 {
    text-align: center;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 2rem auto 0;
  max-width: 800px;
`;

const Input = styled.input`
  outline: none;
  background-color: #eee;
  border: none;
  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
  font-size: 16px;
  font-family: $font-stack;

  &::placeholder {
    color: #999;
  }
`;

const Button = styled.button`
  font-family: "Montserrat";
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  color: #ffffff;
  border: 2px solid #33924a;
  letter-spacing: 2px;
  line-height: 1.7em;
  font-size: 1.45rem;
  font-weight: 500;
  background-color: #33924a;
  padding: 0.3em 1em;
  text-decoration: none;
`;

const SubmissionText = styled.h4`
  margin: 10px;
`;

const Bin = styled.div`
  margin: 10px;
`;

function OttawaEventPage() {
  const [submitSuccessful, setSubmitSuccessful] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    if (submitSuccessful && textRef.current) {
      const text = textRef.current.innerText;
      textRef.current.innerHTML = text
        .split("")
        .map((char, index) =>
          char === " "
            ? `<span class="space" key=${index}>&nbsp;</span>`
            : `<span class="letter" style="display:inline-block" key=${index}>${char}</span>`
        )
        .join("");

      anime.timeline({ loop: false }).add({
        targets: ".letter",
        translateY: ["1.1em", 0],
        translateZ: 0,
        duration: 750,
        delay: (el, i) => 50 * i,
      });
    }
  }, [submitSuccessful]);

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [company, setCompany] = useState();
  const [phone, setPhone] = useState();
  const handleSubmit = async (e) => {
    e.preventDefault();

    const docRef = doc(database, "OttawaEvent", uuidv4());

    const date = Date.now();
    await setDoc(docRef, { date, name, email, company, phone });
    setSubmitSuccessful(true);
  };

  return (
    <>
      <Hero title="Ottawa Chapter Event" />
      <Container>
        <h3>Sign up now for more information! </h3>
        {!submitSuccessful ? (
          <Form onSubmit={handleSubmit}>
            <Input
              onChange={(e) => {
                setName(e.target.value);
              }}
              type="text"
              placeholder="Name"
              required
            />
            <Input
              onChange={(e) => {
                setCompany(e.target.value);
              }}
              type="text"
              placeholder="Company"
              required
            />
            <Input
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              type="text"
              placeholder="Email"
              required
            />
            <Input
              onChange={(e) => {
                setPhone(e.target.value);
              }}
              type="tel"
              placeholder="Phone"
              required
            />
            <Button>Submit</Button>
          </Form>
        ) : (
          <Bin>
            <SubmissionText ref={textRef} className="ml6">
              Thank you for your submission.
            </SubmissionText>
          </Bin>
        )}
      </Container>
    </>
  );
}

export default OttawaEventPage;
