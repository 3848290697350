export default [
  {
    // info: (
    //   <div className="single-gallery__info">
    //     <p className="single-gallery__text">
    //       On September 22nd, EACC held its 2021 golf tournament at Wooden Sticks
    //       Golf Club. The event sold out and everyone had a great time despite
    //       the rainy weather.
    //     </p>
    //     <p className="single-gallery__text">
    //       We wish to thank the sponsors for their support.
    //     </p>
    //     <p className="single-gallery__text single-gallery__text--bold">
    //       Cart Sponsor:
    //     </p>
    //     <p className="single-gallery__text">Abatement Technologies</p>
    //     <p className="single-gallery__text single-gallery__text--bold">
    //       Lunch Sponsors:
    //     </p>
    //     <p className="single-gallery__text">H&S Specialties Inc.</p>
    //     <p className="single-gallery__text">Safety Express</p>
    //     <p className="single-gallery__text single-gallery__text--bold">
    //       Hole Sponsors:
    //     </p>
    //     <p className="single-gallery__text">Abatement Technologies</p>
    //     <p className="single-gallery__text">Alliance Creative Marketing</p>
    //     <p className="single-gallery__text">FCA Insurance</p>
    //     <p className="single-gallery__text">EMC Scientific Inc.</p>
    //     <p className="single-gallery__text">EMSL Analytical, Inc.</p>
    //     <p className="single-gallery__text">Envirosafe Inc.</p>
    //     <p className="single-gallery__text">Hazmasters Inc.</p>
    //     <p className="single-gallery__text">
    //       I & I Construction Services Limited
    //     </p>
    //     <p className="single-gallery__text">Milosh Environmental Inc.</p>
    //     <p className="single-gallery__text">Schouten Environmental</p>
    //     <p className="single-gallery__text">TJ Environmental</p>
    //     <p className="single-gallery__text">WSP</p>
    //     <p className="single-gallery__text"></p>
    //     <p className="single-gallery__text">See you next year!</p>
    //   </div>
    // ),
    subtitle: "2023 Asbestos Awareness Training",
    photos: [
      {
        photo: "/images/PhotoGalleries/2023/2023 Asbestos Awareness Training/1.jpg",
      },
    ],
  },
];
