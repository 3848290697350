import "./JobPost.scss";

function JobPost({ job }) {
  const { date, company, title, link } = job;

  return (
    <div className="job-post">
      <div>
        <p className="job-post__text">
          <span className="job-post__text--bold">Date:</span> {date}
        </p>
        <p className="job-post__text">
          <span className="job-post__text--bold">Company:</span> {company}
        </p>
        <p className="job-post__text">
          <span className="job-post__text--bold">Title:</span> {title}
        </p>
      </div>
      <button className="job-post__button">More Information</button>
    </div>
  );
}
export default JobPost;
