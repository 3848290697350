export default [
  {
    info: (
      <div className="single-gallery__info">
        <p className="single-gallery__text">September 16, 2008</p>
        <p className="single-gallery__text">
          At the recent <strong>Environmental Abatement Council of Canada</strong>golf tournament held on Tuesday, September 16, 2008 at Caledon Woods
          Golf Club, over one hundred and twenty persons enjoyed a great day on the links. Prior to golf, everyone enjoyed a BBQ lunch on the patio.
          After golf, members enjoyed cocktails and dinner. All golfers received an EACC weekend bag and a golf towel, compliments of Caledon Woods.
        </p>

        <p className="single-gallery__text">Thanks are extended to this year’s golf sponsors:</p>

        <p className="single-gallery__text single-gallery__text--bold">
          Lunch: Hazmasters
          <br />
          Dinner Wine: E.I. du Pont Canada Company, Envirosafe
          <br />
          Prizes: EMC Scientific, Hargraft Schofield LP
        </p>

        <p className="single-gallery__text single-gallery__text--bold">
          Hole sponsors: <br />
          Abbot Environmental
          <br />
          DCS
          <br />
          E.I. du Pont Canada <br />
          Envirosafe <br />
          Ferro Canada <br />
          GB Environmental <br />
          I & I Construction <br />
          Inscan <br />
          MRA Abatement d/o Quantum Murray <br />
          Pinchin Environmental <br />
          RGB Inc. <br />
          Restoration Environmental Contractors <br />
          Safetech Environmental <br />
          Safety Express <br />
          Terrasan Environmental <br />
          Tri-Phase Environmental <br />
          Wayne & Harold Smith Construction
        </p>

        <p className="single-gallery__text">
          Thanks are also extended to this year’s Golf Committee – Ian Henderson and Steve Fulford, for their assistance in organizing the event.
        </p>

        <p className="single-gallery__text">
          Don Pinchin, President of EACC, acted as Master of Ceremonies for the evening. He gave a special welcome to Evelyn Stefov from the Ministry
          of Labour. As well, he provided a brief overview of EACC activities.
        </p>

        <p className="single-gallery__text">
          From this year’s golf proceeds, EACC will be making a donation to the Richard Jaan Helmeste Memorial Award. Richard passed away from
          Mesothelioma in the spring of 2003. He was an occupational hygienist with the Ministry of Labour and was genuinely devoted to his work in
          health and safety.
        </p>

        <p className="single-gallery__text">
          <u>Winners included</u>:
        </p>

        <p className="single-gallery__text single-gallery__text--bold">
          Low Team: Ron Boyko, Ron Boyko Sr., Rob Boyko, Ryan Boyko <br />
          Closest to the Pin (sponsored by Golfers Green): Glenn Smith <br />
          Ladies Closest to the Pin: Krystyna Walters <br />
          Longest Drive: Mike Petteplace <br />
          Ladies Longest Drive: Amy Proctor <br />
          Closest to the Rope (sponsored by EMC Scientific): Basil Ansari <br />
          Closest to the Rope (sponsored by EMC Scientific): Steve Kerr <br />
          Longest Putt (sponsored by EMC Scientific): Rich Reboks <br />
          Longest Putt (sponsored by EMC Scientific): Jack Stenthouse <br />
          Grand Prize Winner (flat screen TV – sponsored by Hargraft Schofield): Dan Guitar
        </p>
      </div>
    ),
    subtitle: "2008 EACC Golf Tournament",
    photos: [
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-01.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-02.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-03.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-04.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-05.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-06.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-07.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-08.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-09.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-10.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-11.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-12.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-13.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-14.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-15.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-16.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-17.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-18.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-19.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-20.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-21.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-22.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-23.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-24.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-25.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-26.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-27.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-28.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-29.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-30.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-31.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-32.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-33.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-34.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-35.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-36.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-37.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-38.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-39.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-40.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-41.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-42.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-43.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-44.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-45.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-46.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-47.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-48.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2008/2008-eacc-golf-tournament/2008-Golf-Tournament-49.jpg",
      },
    ],
  },
];
