export default [
  {
    info: (
      <div className="single-gallery__info">
        <p className="single-gallery__text">
          On September 22nd, EACC held its 2021 golf tournament at Wooden Sticks
          Golf Club. The event sold out and everyone had a great time despite
          the rainy weather.
        </p>
        <p className="single-gallery__text">
          We wish to thank the sponsors for their support.
        </p>
        <p className="single-gallery__text single-gallery__text--bold">
          Cart Sponsor:
        </p>
        <p className="single-gallery__text">Abatement Technologies</p>
        <p className="single-gallery__text single-gallery__text--bold">
          Lunch Sponsors:
        </p>
        <p className="single-gallery__text">H&S Specialties Inc.</p>
        <p className="single-gallery__text">Safety Express</p>
        <p className="single-gallery__text single-gallery__text--bold">
          Hole Sponsors:
        </p>
        <p className="single-gallery__text">Abatement Technologies</p>
        <p className="single-gallery__text">Alliance Creative Marketing</p>
        <p className="single-gallery__text">FCA Insurance</p>
        <p className="single-gallery__text">EMC Scientific Inc.</p>
        <p className="single-gallery__text">EMSL Analytical, Inc.</p>
        <p className="single-gallery__text">Envirosafe Inc.</p>
        <p className="single-gallery__text">Hazmasters Inc.</p>
        <p className="single-gallery__text">
          I & I Construction Services Limited
        </p>
        <p className="single-gallery__text">Milosh Environmental Inc.</p>
        <p className="single-gallery__text">Schouten Environmental</p>
        <p className="single-gallery__text">TJ Environmental</p>
        <p className="single-gallery__text">WSP</p>
        <p className="single-gallery__text"></p>
        <p className="single-gallery__text">See you next year!</p>
      </div>
    ),
    subtitle: "2021 EACC Golf Tournament",
    photos: [
      {
        photo: "/images/PhotoGalleries/2021/2021-eacc-golf-tournament/1.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2021/2021-eacc-golf-tournament/2.jpeg",
      },
      {
        photo: "/images/PhotoGalleries/2021/2021-eacc-golf-tournament/3.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2021/2021-eacc-golf-tournament/4.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2021/2021-eacc-golf-tournament/5.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2021/2021-eacc-golf-tournament/6.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2021/2021-eacc-golf-tournament/7.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2021/2021-eacc-golf-tournament/8.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2021/2021-eacc-golf-tournament/9.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2021/2021-eacc-golf-tournament/10.jpg",
      },
    ],
  },
];
