export default [
  {
    image: "images/Radon/BGIS.png",
    name: "BGIS",
    address: "4175 14th Ave.",
    city: "Markham, ON L3R 0J2",
    website: "www.bgis.com",
    contacts: [
      {
        phone: "647-206-5388",
        name: "Bruce Decker",
        email: "Bruce.Decker@bgis.com",
      },
    ],
  },
  {
    image: "images/Radon/CM3.png",
    name: "CM3 Environmental",
    address: "5710 Akins Road",
    city: "Stittsville, ON K2S 1B8",
    website: "www.cm3environmental.com",

    contacts: [
      {
        phone: "613-804-1654",
        name: "Spencer Cochrane",
        email: "spencer@cm3environmental.com",
      },
    ],
  },
  {
    image: "images/Radon/ECOH.png",
    name: "ECOH Environmental Consulting",
    address: "75 Courtneypark Dr. West, Unit 1",
    city: "Mississauga, ON L5W 0E3",
    website: "www.ecoh.ca",
    contacts: [
      {
        phone: "905-795-2800",
        name: "John Kocjan",
        email: "jkocjan@ecoh.ca",
      },
    ],
  },
  {
    image: "images/Radon/ehs.png",
    name: "EHS Partnerships Ltd.",
    address: "406 - 2 Gurdwara Road",
    city: "Ottawa, ON K2E 1A2",
    website: "www.ehsp.com",
    contacts: [
      {
        phone: "613-410-5020",
        name: "Trent Windsor",
        email: "twindsor@ehsp.ca",
      },
    ],
  },
  {
    image: "/images/logos/consultants/englobe.png",
    name: "Englobe",
    address: "353 Bridge Street East",
    city: "Kitchener, ON N2K 2Y5",
    website: "www.englobecorp.com",
    contacts: [
      {
        phone: "519-741-1313, ext. 171244",
        name: "Cory Fountain",
        email: "cory.fountain@englobecorp.com",
      },
    ],
  },
  {
    image: "images/Radon/exp.png",
    name: "EXP Services Inc.",
    address: "15701 Robin's Hill Road",
    city: "London, ON N5V 0A5",
    website: "www.exp.com",
    contacts: [
      {
        phone: "519-963-3000 ext 3408",
        name: "Kimberly Wood",
        email: "Kimberly.Wood@exp.com",
      },
      {
        phone: "905-793-9800 ext 2248",
        name: "Ron Taylor",
        email: "Ron.Taylor@exp.com",
      },
    ],
  },
  {
    image: "images/Radon/ii.png",
    name: "I&I Construction Services Limited",
    address: "70 Newkirk Road, Unit 6",
    city: "Richmond Hill, ON L4C 3G3",
    website: "www.iandi.ca",
    contacts: [
      {
        phone: "905-884-1290",
        name: "Tracy Graham",
        email: "tgraham@iandi.ca",
      },
    ],
  },
  {
    image: "images/Radon/martech.png",
    name: "Martech Group Inc.",
    address: "16-5511 Tomken Road",
    city: "Mississauga, ON L4W 4B8",
    website: "www.martechgroup.ca",
    contacts: [
      {
        phone: "416-291-4663",
        name: "Marwan Essa",
        email: "marwan@martechgroup.ca",
      },
    ],
  },
  {
    image: "images/Radon/ohe.png",
    name: "OHE Consultants",
    address: "311 Matheson Blvd. East",
    city: "Mississauga, ON L4Z 1X8",
    website: "www.oheconsultants.com",
    contacts: [
      {
        phone: "416-707-5335",
        name: "Mahtab Ghadakpour",
        email: "mghadakpour@oheconsultants.com",
      },
      {
        phone: "905-890-9000",
        name: "Hamourabi Elias Ammouri",
        email: "rammouri@oheconsultants.com",
      },
    ],
  },
  {
    image: "images/Radon/pinchin.png",
    name: "Pinchin Ltd.",
    address: "2360 Meadowpine Blvd., Unit 2",
    city: "Mississauga, ON L5N 6S2",
    website: "www.pinchin.com",
    contacts: [
      {
        phone: "905-363-1311",
        name: "Scott Cryer",
        email: "SCryer@Pinchin.com",
      },
    ],
  },
  {
    image: "images/Radon/stantec.png",
    name: "Stantec Consulting Ltd.",
    address: "300W-675 Cochrane Drive",
    city: "Markham, ON L3R 0B8",
    website: "www.stantec.com",
    contacts: [
      {
        phone: "519-585-3831",
        name: "Craig Maunder",
        email: "craig.maunder@stantec.com",
      },
      {
        phone: "226-338-5664",
        name: "Suzanne Wilde",
        email: "suzanne.wilde@stantec.com",
      },
      {
        phone: "403-716-1422",
        name: "Lovy Mangat",
        email: "lovy.mangat@stantec.com",
      },
    ],
  },
  {
    image: "images/Radon/tharris.png",
    name: "T. Harris Environmental Management",
    address: "93 Skyway Avenue, Suite 101",
    city: "Toronto, ON M9W 6N6",
    website: "www.tharris.ca",
    phone: "1-888-ASK-THEM",
    contacts: [
      {
        phone: "416-679-8914 ext. 222",
        name: "John C. Fisher",
        email: "jfisher@tharris.ca",
      },
      {
        name: "Raj Singh",
        email: "rsingh@tharris.ca",
      },
    ],
  },
  {
    image: "images/Radon/wsp.png",
    name: "Englobe",
    address: "3397 American Drive, Units 14 & 15",
    city: "Mississauga, ON L4V 1T8",
    website: "www.englobecorp.com",
    contacts: [
      {
        phone: "1-877-300-4800",
        name: "Steve March",
        email: "Steve.March@englobecorp.com",
      },
    ],
  },
  {
    image: "images/Radon/zero.png",
    name: "Zero Environmental Inc.",
    address: "100 Hanson Ave, Unit A",
    city: "Kitchener, ON N2C 2E2",
    website: "www.zeroenvironmental.com",
    contacts: [
      {
        phone: "519-772-5500",
        name: "Randy Balzer",
        email: "info@zeroenvironmental.com",
      },
    ],
  },
];
