import Hero from "../../components/Hero/Hero";
import GuidelineArticle from "../../components/HomePageComponents/GuidelineArticle/GuidelineArticle";
import News from "../../components/HomePageComponents/News/News";
import Slider from "../../components/HomePageComponents/Slider/Slider";

function HomePage() {
  return (
    <>
      <Hero />
      <News />
      <GuidelineArticle />
      <Slider />
    </>
  );
}
export default HomePage;
