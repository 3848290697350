export default [
  {
    subtitle: "2016 EACC Annual General Meeting and Conference",
    photos: [
      {
        photo: "/images/PhotoGalleries/2016/2016-eacc-agm/2016-AGM-01.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2016/2016-eacc-agm/2016-AGM-02.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2016/2016-eacc-agm/2016-AGM-03.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2016/2016-eacc-agm/2016-AGM-04.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2016/2016-eacc-agm/2016-AGM-05.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2016/2016-eacc-agm/2016-AGM-06.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2016/2016-eacc-agm/2016-AGM-07.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2016/2016-eacc-agm/2016-AGM-08.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2016/2016-eacc-agm/2016-AGM-09.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2016/2016-eacc-agm/2016-AGM-10.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2016/2016-eacc-agm/2016-AGM-11.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2016/2016-eacc-agm/2016-AGM-12.jpg",
      },
    ],
  },
];
