export default [
  {
    info: (
      <div className="single-gallery__info">
        <p className="single-gallery__text">On September 8th EACC held its annual Golf Tournament at Hockley Valley.</p>
        <ul className="single-gallery__list">
          <li className="single-gallery__list-item">Cart Sponsor: Abatement Technologies</li>
          <li className="single-gallery__list-item">Gold Sponsors (Lunch): H&S Specialties and Abbot Environmental</li>
          <li className="single-gallery__list-item">Silver Sponsors (Wine): Stantec Consulting and ECOH</li>
          <li className="single-gallery__list-item">Putting Contest Sponsor: EMSL Canada</li>
          <li className="single-gallery__list-item">
            Bronze Sponsors (Holes):
            <ul className="single-gallery__list single-gallery__list--secondary">
              <li className="single-gallery__list-item">Abbot Environmental Ltd.</li>
              <li className="single-gallery__list-item">Alliance Creative Marketing</li>
              <li className="single-gallery__list-item">Caliber Environmental Construction Services</li>
              <li className="single-gallery__list-item">Canviro Services Corp.</li>
              <li className="single-gallery__list-item">Environmental Services Group Inc.</li>
              <li className="single-gallery__list-item">Envirosafe Inc.</li>
              <li className="single-gallery__list-item">GB Environmental Services</li>
              <li className="single-gallery__list-item">H&S Specialties Inc.</li>
              <li className="single-gallery__list-item">Hazmasters</li>
              <li className="single-gallery__list-item">McGowan Insulations Ltd.</li>
              <li className="single-gallery__list-item">R C White Ltd.</li>
              <li className="single-gallery__list-item">Stantec Consulting Ltd.</li>
            </ul>
          </li>
        </ul>

        <p className="single-gallery__text">The winner of EMSL Canada’s Putting Contest was Ron Campbell.</p>

        <p className="single-gallery__text">The Best Team was: Mark Lai, Chris Broadbent, Brian Edwardson and Ken Reeves</p>
        <p className="single-gallery__text">The Grand Prize Winner of a TV was Jimmy Ball.</p>
      </div>
    ),
    subtitle: "2016 EACC Golf Tournament",
    photos: [
      {
        photo: "/images/PhotoGalleries/2016/2016-eacc-golf-tournament/2016-Golf-Tournament-01.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2016/2016-eacc-golf-tournament/2016-Golf-Tournament-02.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2016/2016-eacc-golf-tournament/2016-Golf-Tournament-03.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2016/2016-eacc-golf-tournament/2016-Golf-Tournament-04.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2016/2016-eacc-golf-tournament/2016-Golf-Tournament-05.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2016/2016-eacc-golf-tournament/2016-Golf-Tournament-06.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2016/2016-eacc-golf-tournament/2016-Golf-Tournament-07.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2016/2016-eacc-golf-tournament/2016-Golf-Tournament-08.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2016/2016-eacc-golf-tournament/2016-Golf-Tournament-09.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2016/2016-eacc-golf-tournament/2016-Golf-Tournament-10.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2016/2016-eacc-golf-tournament/2016-Golf-Tournament-11.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2016/2016-eacc-golf-tournament/2016-Golf-Tournament-12.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2016/2016-eacc-golf-tournament/2016-Golf-Tournament-13.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2016/2016-eacc-golf-tournament/2016-Golf-Tournament-14.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2016/2016-eacc-golf-tournament/2016-Golf-Tournament-15.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2016/2016-eacc-golf-tournament/2016-Golf-Tournament-16.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2016/2016-eacc-golf-tournament/2016-Golf-Tournament-17.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2016/2016-eacc-golf-tournament/2016-Golf-Tournament-18.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2016/2016-eacc-golf-tournament/2016-Golf-Tournament-19.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2016/2016-eacc-golf-tournament/2016-Golf-Tournament-20.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2016/2016-eacc-golf-tournament/2016-Golf-Tournament-21.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2016/2016-eacc-golf-tournament/2016-Golf-Tournament-22.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2016/2016-eacc-golf-tournament/2016-Golf-Tournament-23.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2016/2016-eacc-golf-tournament/2016-Golf-Tournament-24.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2016/2016-eacc-golf-tournament/2016-Golf-Tournament-25.jpg",
      },
    ],
  },
];
