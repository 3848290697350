import Hero from "../../components/Hero/Hero";
import BoardMemberList from "../../boardmembers";
import "./BoardPage.scss";
import BoardMember from "./BoardMember/BoardMember";
function BoardPage() {
  return (
    <>
      <Hero title="Board of Directors" subtitle="2023-2024" />
      <div className="board">
        {BoardMemberList &&
          BoardMemberList.map((boardMember, i) => {
            return <BoardMember key={i} boardMember={boardMember} />;
          })}
      </div>
    </>
  );
}
export default BoardPage;
