import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAJhm7s70ry5vcBfRIE_IXDG703qZ1twZc",
  authDomain: "tca-eacc.firebaseapp.com",
  projectId: "tca-eacc",
  storageBucket: "tca-eacc.appspot.com",
  messagingSenderId: "725702119525",
  appId: "1:725702119525:web:ac464bec42fe4da6fd768c",
  measurementId: "G-4Y8L9ML5T2",
};

const firebaseApp = initializeApp(firebaseConfig);

const database = getFirestore(firebaseApp);

export { firebaseApp, database };
