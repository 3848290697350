import React from "react";
import ReactPlayer from "react-player";

function Webinar() {
  return (
    <div className="player-wrapper">
      <ReactPlayer
        className="react-player fixed-bottom"
        url="/video/EACC-Desbub-Guidline-webinar.mp4"
        width="100%"
        height="100%"
        controls={true}
        autoplay
      />
    </div>
  );
}

export default Webinar;
