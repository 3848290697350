import "../DrugResources/DrugResources.scss";

import Hero from "../../../components/Hero/Hero";
import DrugNav from "../DrugNav/DrugNav";

function DrugResources() {
  return (
    <>
      <Hero title="Drug/Opioid" subtitle="Resources" />
      <DrugNav />
      <div className="drug">
        <h5 className="drug__subheader">Alberta</h5>

        <ul className="drug__list">
          <li className="drug__list-item">Alberta Health Services</li>
          <ul className="drug__list">
            <li className="drug__list-item">
              Fentanyl remediation: guidance for remediators, regulatory
              agencies and professionals <br />
              <a
                className="drug__link"
                href="https://open.alberta.ca/publications/fentanyl-remediation"
                target="_blank"
                rel="noreferrer"
              >
                https://open.alberta.ca/publications/fentanyl-remediation
              </a>
            </li>
          </ul>
        </ul>

        <h5 className="drug__subheader">British Columbia</h5>

        <ul className="drug__list">
          <li className="drug__list-item">
            National Collaborating Centre for Environmental Health
          </li>
          <ul className="drug__list">
            <li className="drug__list-item">
              Clandestine Amphetamine-Derived Drug Laboratories: Remediation
              Guidelines for Residential Settings
              <br />
              <a
                className="drug__link"
                href="https://online-ams.aiha.org/amsssa/ecssashop.show_product_detail?p_mode=detail&p_product_serno=1322"
                rel="noreferrer"
                target="_blank"
              >
                https://ncceh.ca/sites/default/files/Drug_Lab_Remdiation_Guidelines_Dec_2012.pdf
              </a>
            </li>
          </ul>

          <li className="drug__list-item">University of Fraser Valley</li>
          <ul className="drug__list">
            <li className="drug__list-item">
              Cleaning up Former Drug Operations in our Residential
              Neighbourhoods
              <br />
              <a
                className="drug__link"
                href="https://ufv.ca/media/assets/criminal-justice-research/Research-Note-Cleaning-Up-Former-Drug-Operations-in-our-ResidentIal-Neighbourhoods.pdf"
                target="_blank"
                rel="noreferrer"
              >
                https://ufv.ca/media/assets/criminal-justice-research/Research-Note-Cleaning-Up-Former-Drug-Operations-in-our-ResidentIal-Neighbourhoods.pdf
              </a>
            </li>
          </ul>
        </ul>

        <h5 className="drug__subheader">
          American Industrial Hygiene Association
        </h5>

        <ul className="drug__list">
          <li className="drug__list-item">
            Clandestine Methamphetamine Laboratory Assessment and Remediation
            Guidance <br />
            <a
              className="drug__link"
              href="https://online-ams.aiha.org/amsssa/ecssashop.show_product_detail?p_mode=detail&p_product_serno=1322"
              target="_blank"
              rel="noreferrer"
            >
              https://online-ams.aiha.org/amsssa/ecssashop.show_product_detail?p_mode=detail&p_product_serno=1322
            </a>
          </li>
        </ul>

        <h5 className="drug__subheader">State of Utah</h5>

        <ul className="drug__list">
          <li className="drug__list-item">University of Fraser Valley</li>

          <ul className="drug__list">
            <li className="drug__list-item">
              Utah Administrative Code, Rule R392 - 600. Illegal Drug Operations
              Decontamination Standards
              <br />
              <a
                className="drug__link"
                href="https://rules.utah.gov/publicat/code/r392/r392-600.htm"
                target="_blank"
                rel="noreferrer"
              >
                https://rules.utah.gov/publicat/code/r392/r392-600.htm
              </a>
            </li>
            <li className="drug__list-item">
              Utah Administrative Code, Rule R311-500. Illegal Drug Operations
              Site Reporting and Decontamination Act, Decontamination Specialist
              Certification Program
              <br />
              <a
                className="drug__link"
                href="https://rules.utah.gov/publicat/code/r311/r311-500.htm#/T8"
                target="_blank"
                rel="noreferrer"
              >
                https://rules.utah.gov/publicat/code/r311/r311-500.htm#/T8
              </a>
            </li>
          </ul>
        </ul>

        <h5 className="drug__subheader">County of Santa Clara</h5>

        <ul className="drug__list">
          <li className="drug__list-item">
            Department of Environmental Health, Hazardous Materials Compliance
            Division
          </li>

          <ul className="drug__list">
            <li className="drug__list-item">
              Guidelines for Assessment and Remediation of Properties
              Contaminated by Methamphetamine Laboratory Activities
              <br />
              <a
                className="drug__link"
                href="https://hazmat.sccgov.org/sites/g/files/exjcpb471/files/hmcd-121.pdf?__cf_chl_jschl_tk__=WU21JbzHFrLkJOKb80R7VjBfMQpvXRuUwB9Ot0n4qpM-1640068036-0-gaNycGzNCKU"
                target="_blank"
                rel="noreferrer"
              >
                https://hazmat.sccgov.org/sites/g/files/exjcpb471/files/hmcd-121.pdf?__cf_chl_jschl_tk__=WU21JbzHFrLkJOKb80R7VjBfMQpvXRuUwB9Ot0n4qpM-1640068036-0-gaNycGzNCKU
              </a>
            </li>
          </ul>
        </ul>

        <h5 className="drug__subheader">United States of America</h5>

        <ul className="drug__list">
          <li className="drug__list-item">
            US Environmental Protection Agency (EPA)
          </li>

          <ul className="drug__list">
            <li className="drug__list-item">
              Voluntary Guidelines for Methamphetamine and Fentanyl Laboratory
              Cleanup
              <br />
              <a
                className="drug__link"
                href="https://www.epa.gov/emergency-response/voluntary-guidelines-methamphetamine-and-fentanyl-laboratory-cleanup-document"
                target="_blank"
                rel="noreferrer"
              >
                https://www.epa.gov/emergency-response/voluntary-guidelines-methamphetamine-and-fentanyl-laboratory-cleanup-document
              </a>
            </li>
          </ul>
        </ul>

        <h5 className="drug__subheader">New Zealand</h5>

        <ul className="drug__list">
          <li className="drug__list-item">Standards New Zealand</li>

          <ul className="drug__list">
            <li className="drug__list-item">
              NZS 8510;2017 – Testing and decontamination of
              methamphetamine-contaminated properties
              <br />
              <a
                className="drug__link"
                href="https://www.standards.govt.nz/shop/NZS-85102017"
                target="_blank"
                rel="noreferrer"
              >
                https://www.standards.govt.nz/shop/NZS-85102017
              </a>
            </li>
          </ul>

          <li className="drug__list-item">New Zealand Ministry of Health</li>

          <ul className="drug__list">
            <li className="drug__list-item">
              Guidelines for the Remediation of Clandestine Methamphetamine
              Laboratory Sites
              <br />
              <a
                className="drug__link"
                href="https://www.moh.govt.nz/notebook/nbbooks.nsf/0/97DCB4331641B346CC2577AB000515FC/$file/guidelines-remediation-clandestine-meth-lab-sites.pdf"
                target="_blank"
                rel="noreferrer"
              >
                https://www.moh.govt.nz/notebook/nbbooks.nsf/0/97DCB4331641B346CC2577AB000515FC/$file/guidelines-remediation-clandestine-meth-lab-sites.pdf
              </a>
            </li>
          </ul>

          <li className="drug__list-item">
            Institute of Environmental Science and Research Limited for the New
            Zealand Ministry of Health,
          </li>

          <ul className="drug__list">
            <li className="drug__list-item">
              Review of Remediation Standards for Clandestine Methamphetamine
              Laboratories: Risk Assessment Recommendations for a New Zealand
              Standard
              <br />
              <a
                className="drug__link"
                href="https://www.moh.govt.nz/NoteBook/nbbooks.nsf/0/3169D54AC7E94227CC25806D006716AD/$file/methamphetamine-remediation-report-oct2016.pdf"
                target="_blank"
                rel="noreferrer"
              >
                https://www.moh.govt.nz/NoteBook/nbbooks.nsf/0/3169D54AC7E94227CC25806D006716AD/$file/methamphetamine-remediation-report-oct2016.pdf
              </a>
            </li>
          </ul>
        </ul>

        <h5 className="drug__subheader">Australia</h5>

        <ul className="drug__list">
          <li className="drug__list-item">New South Wales (NSW)</li>

          <ul className="drug__list">
            <li className="drug__list-item">
              NSW Remediation Guidelines for Clandestine Drug Laboratories and
              Hydroponic Drug Plantation
              <br />
              <a
                className="drug__link"
                href="https://www.health.nsw.gov.au/environment/hazard/Documents/clan-lab-guidelines.pdf"
                target="_blank"
                rel="noreferrer"
              >
                https://www.health.nsw.gov.au/environment/hazard/Documents/clan-lab-guidelines.pdf
              </a>
            </li>
          </ul>

          <li className="drug__list-item">
            Australia Environmental Health Standing Committee (enHealth),
          </li>

          <ul className="drug__list">
            <li className="drug__list-item">
              enHealth Guidance on: Clandestine Drug Laboratories and Public
              Health Risks
              <br />
              <a
                className="drug__link"
                href="https://www1.health.gov.au/internet/main/publishing.nsf/content/A12B57E41EC9F326CA257BF0001F9E7D/$File/Guidance-Clandestine-Drug-Laboratories-Public-Health.pdf"
                target="_blank"
                rel="noreferrer"
              >
                https://www1.health.gov.au/internet/main/publishing.nsf/content/A12B57E41EC9F326CA257BF0001F9E7D/$File/Guidance-Clandestine-Drug-Laboratories-Public-Health.pdf
              </a>
            </li>
          </ul>
        </ul>
      </div>
    </>
  );
}
export default DrugResources;
