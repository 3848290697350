const Image =
  "/images/PhotoGalleries/2023/2023-eacc-golf-tournament/Golfday-2023-sponsors.png";

export default [
  {
    info: (
      <div className="single-gallery__info">
        <p className="single-gallery__text">
          The Environmental Abatement Council of Canada hosted their annual golf
          tournament on September 20th at Wooden Sticks Golf Club in Uxbridge.
        </p>

        <p className="single-gallery__text">
          All attendees received a Thermos and a Mug, plus ball caps and golf
          towels. They were treated to a buffet lunch before golf and a buffet
          dinner after golf. Also had great opportunity to catch up with
          industry colleagues and enjoyed the great weather.
        </p>

        <p className="single-gallery__text">
          We look forward to next year’s tournament.
        </p>

        <p className="single-gallery__text">Special thanks to our sponsors:</p>

        <img
          className="single-gallery__sponsor-image--large"
          src={Image}
          alt="Golf Day Sponsors"
        />
      </div>
    ),
    subtitle: "2023 EACC Golf Tournament",
    photos: [
      {
        photo: "/images/PhotoGalleries/2023/2023-eacc-golf-tournament/1.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2023/2023-eacc-golf-tournament/2.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2023/2023-eacc-golf-tournament/3.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2023/2023-eacc-golf-tournament/4.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2023/2023-eacc-golf-tournament/5.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2023/2023-eacc-golf-tournament/6.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2023/2023-eacc-golf-tournament/7.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2023/2023-eacc-golf-tournament/8.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2023/2023-eacc-golf-tournament/9.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2023/2023-eacc-golf-tournament/10.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2023/2023-eacc-golf-tournament/11.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2023/2023-eacc-golf-tournament/12.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2023/2023-eacc-golf-tournament/13.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2023/2023-eacc-golf-tournament/14.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2023/2023-eacc-golf-tournament/15.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2023/2023-eacc-golf-tournament/16.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2023/2023-eacc-golf-tournament/17.jpg",
      },
    ],
  },
];
