import Hero from "../../components/Hero/Hero";
import "./JoinPage.scss";

import Ethics from "../../assets/pdfs/Code-of-Ethics.pdf";

import contractor from "../../../src/assets/pdfs/documents/applications/EACC Membership Application - Contractors April 2023 Fillable.pdf";

import consultant from "../../../src/assets/pdfs/documents/applications/EACC Membership Application - Consultants April 2023 Fillable.pdf";

import member from "../../../src/assets/pdfs/documents/applications/EACC Membership Application - General Members and Associates April 2023 Fillable.pdf";

function JoinPage() {
  return (
    <>
      <Hero title="Join" subtitle="EACC Membership Benefits" />
      <div className="join">
        <p className="join__text">
          EACC is dedicated to raising the professional standards in the
          environmental abatement industry. Join a group of your peers to help
          shape and develop the industry. Be involved in committees that affect
          and shape your industry.
        </p>

        <p className="join__text">
          Network with contractors, consultants and suppliers to discuss common
          industry interest that affect our business.
        </p>

        <p className="join__text">
          Make a difference and get involved. EACC is the association to share
          your ideas, voice your opinions and concerns, and to gather
          information as we make the environmental abatement industry a safer
          and more profitable place to work.
        </p>

        <p className="join__text">
          We encourage all persons or companies involved in environmental
          abatement, whether as a contractor, consultant, general contractor or
          representative of a building owner, school board or building operator,
          to become involved in the industry through membership in EACC.
        </p>

        <a className="join__button" href={contractor}>
          Contractor Application Form (PDF)
        </a>

        <a className="join__button" href={consultant}>
          Consultant Application Form (PDF)
        </a>

        <a className="join__button" href={member}>
          General Member & Associate Application Form (PDF)
        </a>

        <p className="join__text join__text--send">Send the application to:</p>

        <p className="join__address">
          Environmental Abatement Council of Canada
        </p>
        <p className="join__address">70 Leek Crescent</p>
        <p className="join__address">Richmond Hill, ON L4B 1H1</p>

        <p className="join__text join__text--email">
          {`Email: `}
          <a
            className="join__text join__text--link"
            href="mailto:jwhite@tcaconnect.com"
          >
            jwhite@tcaconnect.com
          </a>
        </p>

        <a className="join__button join__button--ethics" href={Ethics}>
          EACC Code of Ethics (PDF)
        </a>
      </div>
    </>
  );
}
export default JoinPage;
