import Hero from "../../components/Hero/Hero";
import JobPost from "./JobPost/JobPost";
import "./JobPostingPage.scss";
import jobPDF from "../../assets/pdfs/EACO-Member-Job-Posting-Form.pdf";

function JobPostingPage() {
  return (
    <>
      <Hero title="Job Postings" subtitle="for EACC Members" />
      <div className="jobs">
        <p className="jobs__text">
          EACC provides its members with complimentary job postings. To post a job, please fill out the
          <a className="jobs__link" href={jobPDF}>
            EACC Member Job Posting Form
          </a>
          {` and submit to `}
          <a className="jobs__link" href="mailto:jwhite@tcaconnect.com">
            jwhite@tcaconnect.com
          </a>
          (with the subject line “EACC Job Posting”) for confirmation of your membership before the job is posted.
        </p>

        <p>Currently no job listings, please check again soon.</p>

        {/* <div>
          {JobList.map((job) => {
            return <JobPost job={job} />;
          })}
        </div> */}
      </div>
    </>
  );
}
export default JobPostingPage;
