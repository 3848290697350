import { Link } from "react-router-dom";
import "./GuidelineArticle.scss";

function GuidelineArticle() {
  return (
    <div className="article">
      <div className="article__image" alt="" />
      <div className="article__container">
        <div className="about__line"></div>
        <h2 className="article__title">Best Practices for the Abatement and Hazardous Material Industry</h2>
        <p className="article__text">
          EACC develops industry guidelines to educate and inform stakeholders on safety, preventive measures, meeting responsibilities under the
          Occupational Health and Safety Act, proper assessment and remediation and more.
        </p>
        <Link className="article__link" to={"/guidelines"}>
          <button className="article__button">LEARN MORE</button>
        </Link>
      </div>
    </div>
  );
}
export default GuidelineArticle;
