import React from "react";

export default [
  {
    id: 1,
    question: <h4 className="question__title">Q. What is radon?</h4>,
    answer: (
      <div className="question__answer-wrapper">
        <p className="question__answer">
          A. Radon is a source of radiation, being a radioactive gas that is formed naturally by the breakdown of uranium in soil, rock and water. As
          a gas, radon is slowly released from soil, rock and groundwater that is under our buildings. Radon gas breaks down further to form
          radioactive particles called radon “daughters” or “progeny”, that can also be breathed into the lungs.
        </p>
        <p className="question__answer">
          Radon cannot be detected by the senses (i.e. it is colourless, odourless and tasteless), however, it can be detected with special
          instruments. When radon is released from the ground outside, it mixes with fresh air and is rapidly diluted, resulting in concentrations too
          low to be of concern. However, when radon enters an enclosed space, such as a house or basement, it can reach high concentrations and become
          a health risk.
        </p>
        <p className="question__answer">
          Radon concentrations inside buildings fluctuate seasonally, but are usually higher in winter than in summer. The primary driving force of
          radon entry into buildings is the pressure differential between the inside and outside of the building that results from the stack-effect,
          which is always strongest in winter months. In addition, the closing of doors and windows in winter reduces fresh air exchange and allows
          for radon concentrations to increase.
        </p>
      </div>
    ),
  },

  {
    id: 2,
    question: <h4 className="question__title">Q. What is the Canadian guideline for radon in indoor air?</h4>,
    answer: (
      <div className="question__answer-wrapper">
        <p className="question__answer">
          A. The Canadian guideline for radon in indoor air provides Canadians with guidance regarding testing and when remedial action should be
          taken to reduce radon levels. The Canadian Guideline is as follows:
        </p>
        <p className="question__answer">
          “Remedial measures should be undertaken in a dwelling whenever the average annual radon concentration exceeds 200 becquerels per cubic metre
          (200 Bq/m³) in the normal occupancy area. The higher the radon concentration, the sooner remedial measures should be undertaken. When
          remedial action is taken, the radon levels should be reduced to a value as low as practicable. The construction of new dwellings should
          employ techniques that will minimize radon entry and will facilitate post-construction radon removal, should this subsequently prove
          necessary.”
        </p>
      </div>
    ),
  },

  {
    id: 3,
    question: (
      <h4 className="question__title">
        Q. Why did Health Canada announce a lowering of the guidelines for acceptable levels of radon in the house from 800 to 200 Bq/m³ in June 2007?
      </h4>
    ),
    answer: (
      <div className="question__answer-wrapper">
        <p className="question__answer">
          A. Health Canada’s previous guideline had been in place since 1988. The original guideline was based on estimates of lung cancer risk from
          studies of underground uranium miners exposed to very high levels of radon. Uncertainty existed with the projection of lung cancer risk from
          occupational radon exposure to the public for residential exposures.
        </p>
        <p className="question__answer">
          {`Recent scientific studies have conclusively linked the risk of developing lung cancer associated with levels of radon found in some homes
          and workplaces. These studies prompted the federal government to collaborate with provincial and territorial governments to review the
          federal radon guidelines in 2005. Following a risk assessment and a public consultation, the revised guideline was approved by the `}
          <a
            className="question__link"
            href="https://www.canada.ca/en/health-canada/services/health-risks-safety/radiation/understanding/federal-provincial-territorial-radiation-protection-committee.html"
          >
            Federal Provincial Territorial Radiation Protection Committee
          </a>
          {` in October 2006.`}
        </p>
      </div>
    ),
  },

  {
    id: 4,
    question: <h4 className="question__title">Q. What is a Becquerel?</h4>,
    answer: (
      <div className="question__answer-wrapper">
        <p className="question__answer">
          A. The becquerel is a metric unit, scientists use to measure the number of radioactive decays of radon atoms. One becquerel corresponds to
          one nuclear disintegration per second, thus a reading at the Canadian exposure limit of 200 Bq/m³ is 200 nuclear disintegrations per second
          per cubic meter of air. A higher becquerel value means greater radioactivity and the greater the risk to building occupants. Radon levels
          are measured in units of becquerels per cubic meter of air (Bq/m³).
        </p>
      </div>
    ),
  },

  {
    id: 5,
    question: (
      <h4 className="question__title">Q. What is the difference between becquerels per cubic meter (Bq/m³) and picocuries per litre (pCi/L)?</h4>
    ),
    answer: (
      <div className="question__answer-wrapper">
        <p className="question__answer">
          A. Much information about radon comes from the United States of America (USA) where different radon measurement units are used. In Canada
          and other countries, the concentration of radon in the air is measured in units of becquerels per cubic meter of air (Bq/m³), whereas the
          USA uses picocuries per litre of air (pCi/L). Both these units are measurements of radioactive concentration (i.e. radon in air). One pCi/L
          is equivalent to 37 Bq/m³.
        </p>
      </div>
    ),
  },

  {
    id: 6,
    question: (
      <h4 className="question__title">
        Q. What is Health Canada's reaction to the World Health Organization's (WHO) radon action level of 100 Bq/m³?
      </h4>
    ),
    answer: (
      <div className="question__answer-wrapper">
        <p className="question__answer">
          A. Health Canada’s response to this question is that Canada’s radon guideline is well within the range recommended by the WHO. The WHO’s
          recommended reference level is 100 Bq/m³, with an upper limit of 300 Bq/m³ that should not be exceeded.
        </p>
        <p className="question__answer">
          Health Canada, in consultation with the Federal Provincial Territorial Radiation Protection Committee (FPTRPC) set a guideline (also known
          as a reference level) of 200 Bq/m³ for annual indoor radon concentration. Health Canada and the FPTRPC have reviewed and discussed the WHO’s
          recommendations and have decided not to lower the Canadian radon guideline as it falls within the recommended range of 100 to 300 Bq/m³.
        </p>
        <p className="question__answer">
          Note that the WHO recommendation of 100 Bq/m³, as set forth within the “WHO Handbook on Indoor Radon, A Public Health Perspective” indicates
          a level above 100 Bq/m³ should only be set if the 100 Bq/m³ level “cannot be reached under the prevailing country-specific conditions”.
        </p>
        <p className="question__answer">
          Since radon is a carcinogen (cancer causing agent) the principal of lowering concentrations to As Low As Reasonably Practicable (ALARA)
          applies. Individuals and companies are free to choose an action level that is less than Health Canada’s level of 200 Bq/m³.
        </p>
      </div>
    ),
  },

  {
    id: 7,
    question: <h4 className="question__title">Q. How can radon affect my health?</h4>,
    answer: (
      <div className="question__answer-wrapper">
        <p className="question__answer">
          A. Radon causes lung cancer and is the leading cause of lung cancer in non-smokers. Estimates suggest 16% of lung cancer and 3,200 deaths in
          Canada are attributable to radon exposure.
        </p>
        <p className="question__answer">
          The only known health risk associated with exposure to high levels of radon in indoor air is an increased lifetime risk of developing lung
          cancer. The higher the radon level the greater the risk of developing lung cancer. Radon gas and radon progeny in the air can be breathed
          into the lungs where nuclear decay continues to occur, further breaking these substances down into other radioactive elements, primarily
          forms (isotopes) of polonium, bismuth and lead, which are also referred to as radon progeny or radon daughters. Radon progeny are solid
          particles and can remain lodged in the lungs. Each time a substance is broken down to become another substance, radiation is released.
        </p>
        <p className="question__answer">
          Radon and radon progeny emit radiation which damages nearby lung tissue and DNA. This damage can lead to the development of cancer.
        </p>
        <p className="question__answer">
          The risk from radon exposure is long term and depends on the level of radon, how long a person is exposed and their smoking habits,
          considered cumulatively over that person’s lifetime.
        </p>
      </div>
    ),
  },

  {
    id: 8,
    question: (
      <h4 className="question__title">
        Q. Is there any risk associated with exposure to radon at levels below the Health Canada guideline limit of 200Bq/m³?
      </h4>
    ),
    answer: (
      <div className="question__answer-wrapper">
        <p className="question__answer">
          A. While the risk of developing lung cancer decreases as the radon levels decrease, there is no level of radon (i.e. radiation) exposure
          that is considered 100% safe. Lower regulated and/or guideline limits have been set in other jurisdictions, with the US Environmental
          Protection Agency (EPA) indicating action must be taken at 148 Bq/m³, and the World Health Organization suggesting a lower limit of 100
          Bq/m³ as an “acceptable” indoor radon level.
        </p>
        <p className="question__answer">
          As radon is present in low concentrations in outdoor air, it is not possible to eliminate all human exposure to radon. However, a general
          principle for controlling exposure to any form of radiation, including radon, is to reduce the levels to which people are exposed to levels
          As Low As Reasonably Practicable (ALARA).
        </p>
      </div>
    ),
  },
  {
    id: 9,
    question: <h4 className="question__title">Q. I am a smoker. Does radon affect me more than a non-smoker?</h4>,
    answer: (
      <div className="question__answer-wrapper">
        <p className="question__answer">
          A. Yes, the risk from radon exposure for a smoker (including those exposed to secondhand smoke) is greater than for a non-smoker.
        </p>
      </div>
    ),
  },
  {
    id: 10,
    question: <h4 className="question__title">Q. Are children more at risk from radon than adults?</h4>,
    answer: (
      <div className="question__answer-wrapper">
        <p className="question__answer">
          A. Children have been reported to be at greater risk than adults for radon exposure. Since radiation damage from radon is cumulative, it is
          important to reduce radon exposure as soon as possible in one’s life to minimize lifetime risks.
        </p>
      </div>
    ),
  },
  {
    id: 11,
    question: <h4 className="question__title">Q. What about drinking water that contains radon?</h4>,
    answer: (
      <div className="question__answer-wrapper">
        <p className="question__answer">
          A. Water from municipal distribution systems tends to have very low radon levels due to treatment processes and retention time. Elevated
          radon in water tends to be more prevalent in water sourced directly from groundwater wells. When water that contains radon is agitated
          during daily household usage (e.g. showers, dishwashing, etc.), radon gas escapes from the water and goes into the air. The primary health
          risk is from inhalation of radon with ingestion being of secondary concern. Therefore, radon exposure control begins with reducing radon in
          indoor air that comes from soils under a building, followed by reduction of radon in water if overall indoor radon levels have not been
          adequately reduced.
        </p>
      </div>
    ),
  },
  {
    id: 12,
    question: <h4 className="question__title">Q. Where in Canada are radon levels the highest?</h4>,
    answer: (
      <div className="question__answer-wrapper">
        <p className="question__answer">
          A. Radon is found in almost every home and workplace, but concentrations will vary even if buildings are similar and located beside each
          other. Radon concentrations differ greatly throughout Canada but are usually higher in areas where there is a high concentration of uranium
          in underlying rock and soil. The only way to know the radon levels in a building is to test, and therefore all occupied buildings should be
          tested. This also supports the need for all new construction to incorporate radon control measures.
        </p>
      </div>
    ),
  },
  {
    id: 13,
    title: <h3 className="question__header">Radon Mitigation in Existing Homes and Workplaces</h3>,
    question: <h4 className="question__title">Q. How much will it cost to mitigate my house or workplace?</h4>,
    answer: (
      <div className="question__answer-wrapper">
        <p className="question__answer">
          A. The cost for a radon mitigation system can vary significantly depending on building type and Client requirements. As such, contact a
          C-NRPP certified mitigator for information regarding costs associated with the design and installation of radon mitigation systems.
        </p>
      </div>
    ),
  },
  {
    id: 14,

    question: (
      <h4 className="question__title">Q. What sort of reduction in radon levels can be expected as a result of installing a mitigation system?</h4>
    ),
    answer: (
      <div className="question__answer-wrapper">
        <p className="question__answer">
          A. According to a Health Canada Study of 52 homes that had active soil depressurization radon mitigation systems installed, the average
          reduction in radon levels was 90%. In many cases, even greater reductions are possible.
        </p>
        <p className="question__answer">
          Consider retaining an EACC member who is a C-NRPP certified Radon Mitigation Professional to conduct an initial review of the building,
          which may include “feasibility” or “pressure field communication” testing, prior to committing to a full active depressurization radon
          mitigation system installation. Pressure field communications testing will establish the extent to which air can move through the soil under
          the building and determine the feasibility of installing an active soil depressurization (ASD) radon remediation system.
        </p>
        <p className="question__answer">
          Mitigation relying on increased ventilation rates typically has a lower success rate and lower reduction rate. This method can be
          successful, but also usually carries significant ongoing energy and operational expenses, due to loss of conditioned air and increased wear
          and tear on ventilation equipment. These systems can also be easily defeated by poor maintenance or interference by occupants, maintenance
          staff, landlords or outside contractors.
        </p>
      </div>
    ),
  },
  {
    id: 15,
    title: <h3 className="question__header">Residential Tenancies & Radon</h3>,
    question: (
      <h4 className="question__title">
        Q. I am renting a house/apartment and am concerned about radon. Is my landlord required to test for radon if I ask him to do so?
      </h4>
    ),
    answer: (
      <div className="question__answer-wrapper">
        <p className="question__answer">
          A. No, there is currently no legal requirement for a landlord to test a rental property; you will have to do it yourself unless your
          landlord agrees to test. It is recommended you work toward an agreement with your landlord if possible. You may need to involve your local
          property standards office to help resolve the issue.
        </p>
      </div>
    ),
  },
  {
    id: 16,
    question: (
      <h4 className="question__title">
        Q. I tested my rental house/apartment and the radon reading was high, is my landlord required to fix this problem?
      </h4>
    ),
    answer: (
      <div className="question__answer-wrapper">
        <p className="question__answer">
          A. No, the Canadian guideline for radon in indoor air is voluntary, there is currently no legal requirement for the landlord to remediate to
          lower the radon level. It is recommended you work toward an agreement with your landlord if possible. You may need to involve your local
          property standards office to help resolve the issue.
        </p>
      </div>
    ),
  },
];
