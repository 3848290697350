export default [
  {
    info: (
      <div className="single-gallery__info">
        <p className="single-gallery__text">September 15, 2009</p>
        <p className="single-gallery__text">
          At the recent <strong> Environmental Abatement Council of Canada</strong>
          {` golf tournament held on Tuesday, September 15, 2009 at Caledon
          Woods Golf Club, over one hundred and fifteen persons enjoyed a great day on the course. The day started off with plenty of sunshine, which
          continued through to the evening. Prior to golf, everyone enjoyed a BBQ lunch on the patio. After golf, members enjoyed cocktails and
          dinner. All golfers received an EACC cooler bag, EACC water bottle and EACC sleeve of balls and a hat, compliments of ClubLink.`}
        </p>

        <p className="single-gallery__text">
          Thanks were extended to Ian Henderson and Steve Fulford for their assistance in organizing the event. As well, thanks were extended to this
          year’s golf sponsors:
        </p>

        <p className="single-gallery__text single-gallery__text--bold">
          Hole-In-One: Pinchin Environmental Ltd.
          <br /> Grand Prize: Ferro Canada Inc.
          <br /> Lunch: Hazmasters Environmental, Krytiuk Specialty Contracting
          <br /> Dinner Wine: Hargraft Schofield LP
          <br /> Closet to the Rope, Longest Drive & Longest Putt: Hazmasters Environmental <br /> Closet to the Line & Closest to the Pin: Polytarp
          Products
        </p>

        <p className="single-gallery__text single-gallery__text--bold">
          Hole sponsors: <br />
          Abbot Environmental Inc.
          <br />
          ECOH Management Inc.
          <br />
          EMC Scientific Inc.
          <br />
          Envirosafe Inc.
          <br />
          Ferro Canada Inc.
          <br />
          GB Environmental Services
          <br />
          I & I Construct ion Services
          <br />
          JMX Environmental Inc.
          <br />
          Pinchin Environmental Ltd.
          <br />
          Restoration Environmental
          <br />
          Safetech Environmental
          <br />
          Safety Express Ltd.
          <br />
          T. Harris Environmental
          <br />
          Terrasan Environmental
          <br />
          Tri-Phase Environmental
        </p>

        <p className="single-gallery__text">
          From this year’s golf proceeds, EACC will be making a donation to the Richard Jaan Helmeste Memorial Award. Richard passed away from
          Mesothelioma in the spring of 2003. He was an occupational hygienist with the Ministry of Labour and was genuinely devoted to his work in
          health and safety.
        </p>

        <p className="single-gallery__text">
          <u>Winners included</u>:
        </p>

        <p className="single-gallery__text single-gallery__text--bold">
          Low Team: Mark Krytiuk, Peter Krytiuk, Alex Muzzo, Melissa La Hay <br />
          Mens Longest Drive: Paul Mattila <br />
          Ladies Longest Drive: Jennifer Balcerak <br />
          Mens Closest to the Pin: Ron Boyko <br />
          Ladies Closest to the Pin: Amy Proctor <br />
          Mens Closest to the Line: Jamie Green <br />
          Ladies Closest to the Line: Catherine Barker <br />
          Mens Closest to the Rope: Fred Atrash <br />
          Ladies Closest to the Rope: Catherine Barker <br />
          Mens Longest Putt: Steve Kerr <br />
          Ladies Longest Putt: Christine White <br />
          Grand Prize Winner: Anna Tarakanova
        </p>
      </div>
    ),
    subtitle: "2009 EACC Golf Tournament",
    photos: [
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-01.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-02.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-03.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-04.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-05.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-06.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-07.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-08.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-09.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-10.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-11.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-12.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-13.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-14.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-15.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-16.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-17.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-18.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-19.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-20.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-21.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-22.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-23.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-24.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-25.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-26.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-27.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-28.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-29.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-30.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-31.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-32.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-33.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-34.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-35.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-36.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-37.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-38.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-39.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-40.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-41.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-42.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-43.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-44.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-45.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-46.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-47.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-48.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-49.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-50.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-51.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-52.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-53.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-54.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-55.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-56.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2009/2009-eacc-golf-tournament/2009-Golf-Tournament-57.jpg",
      },
    ],
  },
];
