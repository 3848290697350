import Hero from "../../../components/Hero/Hero";
import "./PressRelease.scss";
import Logo from "../../../assets/EACC-Logo.png";

function PressRelease() {
  return (
    <>
      <Hero
        title={"Press Release"}
        subtitle="EACO is now EACC, the Environmental Abatement Council of Canada"
      />
      <div className="press">
        <h3 className="press__header">REBRANDING PRESS RELEASE</h3>

        <p className="press__text"></p>

        <p className="press__text">June 10, 2021</p>
        <p className="press__text">
          EACO is now EACC, the
          <span className="press__text press__text--underline press__text--bold">
            Environmental Abatement Council of Canada
          </span>
        </p>

        <p className="press__text">
          It is with great pleasure that I announce the rebranding of this
          association to the Environmental Abatement Council of Canada (EACC).
          This change is the next step in our strategic plan to becoming
          recognized as the leading Canadian organization representing the
          environmental abatement industry. The mission statement of EACC
          remains the same,
        </p>

        <ul className="press__list">
          <li className="press__list-item">
            The betterment and promotion of the environmental abatement and
            hazardous materials industry.
          </li>
          <li className="press__list-item">
            Promoting and practicing the highest standards of conduct among our
            members.
          </li>
          <li className="press__list-item">
            Promoting and practicing the highest standards of conduct among our
            members.
          </li>
        </ul>

        <p className="press__text">
          Our scope is expanding to truly represent the Canadian environmental
          abatement industry from coast to coast.
        </p>

        <p className="press__text">
          I want to extend my sincerest gratitude to the Directors of this
          association for the hard work and dedication that was required to get
          us to this point. Similarly, thank you so much to the multitude of
          volunteers, and the membership in general, for the continuing support
          of this association. It is only with the long standing support of our
          loyal membership that makes this possible. We should all feel very
          proud about achieving this milestone.
        </p>
        <p className="press__text">
          As an association we need to embrace this change and look forward to
          making the most of a bright future that is full of opportunity. This
          will begin by expanding the EACC network by cultivating engagement
          with stakeholders in the environmental abatement industry, in all
          Canadian jurisdictions, including consultants, contractors,
          educational institutions, equipment suppliers, governmental liaisons,
          insurance representatives, laboratory services, and those with a
          general interest in this industry.
        </p>

        <p className="press__text">
          Increasing the EACC network will open opportunities for the entire
          association and will facilitate the goals of supporting members as
          well as promoting the highest standards of conduct. I encourage all
          stakeholders involved in the environmental abatement industry to join
          EACC and to follow us at
          <a
            className="press__link"
            href="www.eaccan.com"
            target="_blank"
            rel="noreferrer"
          >
            www.eaccan.com
          </a>
        </p>

        <p className="press__text press__text--bold">
          John P. Kocjan, CRSP, ROH <br />
          President
          <br />
          Environmental Abatement Council of Canada
        </p>

        <img className="press__logo" src={Logo} alt="Press Logo" />
      </div>
    </>
  );
}
export default PressRelease;
