import Hero from "../../components/Hero/Hero";
import "./DrugPage.scss";
import DrugNav from "./DrugNav/DrugNav";
import { Link } from "react-router-dom";

function DrugPage() {
  return (
    <>
      <Hero title="Drug/Opioid" subtitle="Home" />
      <DrugNav />
      <div className="drug">
        <p className="drug__text">
          The purpose of this page is to provide information and resources regarding the remediation of drug and opioid contaminated sites. Drugs and
          opioids referenced on this page include:
        </p>
        <ul className="drug__list">
          <li className="drug__list-item">Methamphetamine and clandestine laboratories</li>
          <li className="drug__list-item">Fentanyl and its analogues</li>
        </ul>
        <h5 className="drug__subheader">Methamphetamine and Clandestine Laboratories</h5>
        <p className="drug__text">
          Methamphetamine belongs to a group of drugs called amphetamines. Amphetamines are stimulants that activate systems in the brain and central
          nervous system. Methamphetamine affects the body by increasing dopamine. Dopamine is a neurotransmitter in the brain that stimulates
          feelings such as pleasure and motivation. It affects thinking, problem solving, and memory. Methamphetamine can cause an intense high,
          followed by a crash that causes irritability and depression. Over time, methamphetamine use can damage parts of the brain that control
          thinking, problem solving, and memory. Other long-term health effects include seeing and hearing things that are not there, damage to teeth
          and gums (‘meth mouth’), and loss of appetite.
        </p>
        <p className="drug__text">
          Methamphetamine is mainly a recreational use drug and is manufactured illegally in clandestine laboratories. A clandestine laboratory is a
          secret location where illegal drugs are manufactured. Methamphetamine can be found in various forms, including a powder or crystalline form
          and goes by various names including names such as “crystal meth”, “ice”, “crystal”, “crank” or “glass”. There are countless recipes to
          illegally manufacture methamphetamine.
        </p>
        <p className="drug__text">
          The remediation of clandestine methamphetamine laboratories poses various hazards to first responders, remediation contractors, occupants,
          and property owners. There are numerous guidelines available addressing the safe remediation of clandestine methamphetamine laboratories.
          The Resources Tab provides links to various guidelines.
        </p>
        <p className="drug__text">
          <br />
        </p>
        <h5 className="drug__subheader">Fentanyl and its Analogues</h5>
        <p className="drug__text">
          Opioids are a class of drugs that are prescribed for pain relief and can be addictive if not used as prescribed. Fentanyl is a type of
          synthetic opioid, often prescribed and used for pain relief. Fentanyl impacts the body by binding to the opioid receptors in the brain that
          control pain and manage emotion. Fentanyl produces effects such as pain relief, euphoria, sedation, and relaxation. Other effects can
          include nausea, vomiting, inability to urinate, constriction of the pupil, and respiratory dysfunction. It is much stronger than most other
          opioids, which makes fentanyl very dangerous and addictive if not used correctly.
        </p>
        <p className="drug__text">
          Fentanyl can be manufactured in illegal drug labs or sold or stolen from individuals who have a prescription. Fentanyl can come in various
          forms; however most street fentanyl is in powder form and the powder can be mixed with other drugs.
        </p>
        <p className="drug__text">
          Fentanyl analogues are drugs with a similar, but slightly different, chemical make-up. Carfentanil (or carfentanyl) is an example of a
          fentanyl analogue. Carfentanil is also a synthetic opioid normally used as a sedative for large animals. It is much stronger and more toxic
          than fentanyl.
        </p>
        <p className="drug__text">
          The remediation of sites contaminated with fentanyl and its analogues pose various hazards to first responders, remediation contractors,
          occupants, and property owners. There are numerous guidelines available addressing the safe remediation of sites contaminated with fentanyl
          and its analogues. The Resources Tab provides links to various guidelines.
        </p>
        <p className="drug__text">
          <br />
        </p>
        <h5 className="drug__subheader">Remediation Guidelines</h5>
        <p className="drug__text">
          {`Currently there is no Canadian guideline regarding the remediation of drug and opioid contaminated sites. Numerous jurisdictions around the
          world have published their own guidelines regarding the remediation of drug and opioid contaminated sites. Links to these guidelines can be
          found under the `}
          <Link className="drug__link" to="/drug-opioid-resources">
            Resources Tab
          </Link>
          .
        </p>
      </div>
    </>
  );
}
export default DrugPage;
