import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Hero from "../../../components/Hero/Hero";
import MembersComponent from "../../../components/MembersComponent/MembersComponent";
import PreQualifiedContractorsList from "../../../PreQualifiedContractors";
import ConsultantsList from "../../../Consultants";
import GeneralMembersList from "../../../GeneralMembers";
import AssociatesList from "../../../Associates";

function MembersList() {
  const [data, setData] = useState("");
  const [subtitle, setSubtitle] = useState("");

  const params = useParams();

  const paramCheck = () => {
    if (params.page === "pre-qualified-contractors") {
      setData(PreQualifiedContractorsList);
      setSubtitle("Pre-Qualified Contractors");
    }
    if (params.page === "consultants") {
      setData(ConsultantsList);
      setSubtitle("Pre-Qualified Consultants");
    }
    if (params.page === "general-members") {
      setData(GeneralMembersList);
      setSubtitle("General Members");
    }
    if (params.page === "associates") {
      setData(AssociatesList);
      setSubtitle("Associates");
    }
  };

  useEffect(() => {
    paramCheck();
  }, []);

  // const downloadCSV = (data) => {
  //   if (!data.length) {
  //     console.error("No data provided.");
  //     return;
  //   }

  //   // Adjust headers based on the provided data structure
  //   const orderedHeaders = [
  //     "company",
  //     "name",
  //     "phone",
  //     "fax",
  //     "email",
  //     "address",
  //     "city",
  //     "province",
  //     "postal",
  //     "website",
  //     "latitude",
  //     "longitude",
  //     "profile",
  //   ];

  //   const csvRows = [];
  //   csvRows.push(orderedHeaders.join(","));

  //   data.forEach((row) => {
  //     row.contacts.forEach((contact) => {
  //       const values = orderedHeaders.map((header) => {
  //         if (header === "profile") {
  //           return `"${row.profile.join(" ")}"`; // Combine profile array into a string
  //         }
  //         return `"${contact[header] || row[header] || ""}"`;
  //       });
  //       csvRows.push(values.join(","));
  //     });
  //   });

  //   const csvContent = csvRows.join("\n");

  //   const blob = new Blob([csvContent], { type: "text/csv" });
  //   const url = URL.createObjectURL(blob);

  //   const link = document.createElement("a");
  //   link.href = url;
  //   link.download = "consultants_data.csv";
  //   link.click();

  //   URL.revokeObjectURL(url);
  // };

  return (
    <div>
      <Hero title={"Members"} subtitle={subtitle} />

      {/* <button
        onClick={() => {
          downloadCSV(data);
        }}
      >
        download list{" "}
      </button> */}
      {data && <MembersComponent data={data} />}
    </div>
  );
}

export default MembersList;
