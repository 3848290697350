import { Link } from "react-router-dom";
import "./RadonNav.scss";
import burgerMenu from "../../../assets/icons/burger.svg";
import { useState } from "react";

function RadonNav() {
  const [active, setActive] = useState(false);

  return (
    <div className="radon-nav">
      <div className="radon-nav__icon-wrapper">
        {active && (
          <ul className="radon-nav__mobile-list">
            <Link className="radon-nav__mobile-list-item" to="/radon">
              <li>Radon Home</li>
            </Link>
            <Link className="radon-nav__mobile-list-item" to="/radon-101">
              <li>Radon 101</li>{" "}
            </Link>
            <Link className="radon-nav__mobile-list-item" to="/radon-certified-members">
              <li>Radon Certified Members</li>
            </Link>
            <Link className="radon-nav__mobile-list-item" to="/radon-resources">
              <li>Radon Resources</li>
            </Link>
          </ul>
        )}
        <img
          className="radon-nav__icon"
          src={burgerMenu}
          alt=""
          onClick={() => {
            setActive(!active);
          }}
        />
      </div>

      <ul className="radon-nav__list radon-nav__list--tablet">
        <Link className="radon-nav__list-item" to="/radon">
          <li>Radon Home</li>
        </Link>
        <Link className="radon-nav__list-item" to="/radon-101">
          <li>Radon 101</li>{" "}
        </Link>
        <Link className="radon-nav__list-item" to="/radon-certified-members">
          <li>Radon Certified Members</li>
        </Link>
        <Link className="radon-nav__list-item" to="/radon-resources">
          <li>Radon Resources</li>
        </Link>
      </ul>
    </div>
  );
}
export default RadonNav;
