import Hero from "../../../components/Hero/Hero";
import RadonNav from "../RadonNav/RadonNav";
import "./RadonMembers.scss";
import RadonMembersList from "./RadonMemberList";
import RadonMember from "./RadonMember/RadonMember";
function RadonMembers() {
  return (
    <>
      <Hero title="Radon" subtitle="Certified Members" />
      <RadonNav />
      <div className="radon-members">
        <h3 className="radon-members__header">
          EACC Members Certified for Radon
        </h3>

        {RadonMembersList && (
          <div className="radon-members__container">
            {RadonMembersList.map((member) => {
              return <RadonMember member={member} />;
            })}
          </div>
        )}
        <h3 className="radon-members__header">Other Sources</h3>

        <div className="radon-members__container">
          <div className="radon-member">
            <img
              className="radon-member__image"
              src={"/images/Radon/cnrpp.png"}
              alt="CNRPP Logo"
            />
            <div className="radon-member__info">
              <p className="radon-member__text">
                {`Canadian - National Radon Proficiency Program: `}
                <a
                  className="radon-member__link"
                  href={"https://c-nrpp.ca/find-a-professional/"}
                  target="_blank"
                  rel="noreferrer"
                >
                  Find a Professional
                </a>
              </p>
            </div>
          </div>

          <div className="radon-member">
            <img
              className="radon-member__image"
              src={"/images/Radon/carst.png"}
              alt="Carst Logo"
            />
            <div className="radon-member__info">
              <p className="radon-member__text">
                Canadian - National Radon Proficiency Program: Find a
                Professional
              </p>

              <a
                className="radon-member__link"
                href={"https://carst.ca/memberlist"}
                target="_blank"
                rel="noreferrer"
              >
                Canadian Association of Radon Scientists and Technologists
                (CARST)
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default RadonMembers;
