export default [
  {
    id: "ch",
    contacts: [
      {
        company: "City of Toronto",
        name: "Sara Reid",
        phone: "416-392-8623",
        fax: "",
        email: "sara.reid@toronto.ca",
        address: "Metro Hall, 55 John St., 2nd Floor",
        city: "Toronto",
        province: "ON",
        postal: "M5V 3C6",
        website: "www.toronto.ca",
        latitude: 43.64626406141859,
        longitude: -79.38870347116516,
      },
    ],
    logo: "",
    profile: [],
  },
  {
    id: "ch2",
    contacts: [
      {
        company: "City of Toronto",
        name: "Inder Bhamra",
        phone: "437 331-9387",
        fax: "",
        email: "Inder.Bhamra@toronto.ca",
        address: "Metro Hall, 55 John St., 2nd Floor",
        city: "Toronto",
        province: "ON",
        postal: "M5V 3C6",
        website: "www.toronto.ca",
        latitude: 43.64626406141859,
        longitude: -79.38870347116516,
      },
    ],
    logo: "",
    profile: [],
  },
  {
    id: 1,
    contacts: [
      {
        company: "Durham College",
        name: "Dimitri Stathopoulos",
        phone: "905-721-2000",
        fax: "",
        email: "Dimitri.Stathopoulos@durhamcollege.ca",
        address: "2000 Simcoe Street North",
        city: "Oshawa",
        province: "Ontario",
        postal: "L1G 0C5",
        website: "www.durhamcollege.ca",
        latitude: 43.65048563112382,
        longitude: -79.38735420190348,
      },
    ],
    logo: "/images/logos/associates/durham-college.png",
    services: "Education",
    profile: [],
    custom: (
      <p className="members-component__text">
        At Durham College (DC), the student experience comes first. With
        campuses in Oshawa and Whitby and a learning site in Pickering, we offer
        more than 13,400 full-time post-secondary and apprenticeship students
        access to more than 140 full-time and nine apprenticeship programs,
        including
        <a
          className="members-component__weblink"
          href="https://durhamcollege.ca/programs/environmental-technology"
          target="_blank"
          rel="noreferrer"
        >
          Environmental Technology, Water Quality Technician
        </a>
        and Environment Health and Safety Management, graduate certificate
        (launching fall 2020).
      </p>
    ),
  },
  {
    id: 2,
    contacts: [
      {
        company: "Legislative Assembly of Ontario",
        name: "Julia Harris",
        phone: "416-325-9995",
        fax: "416-325-3593",
        email: "julia_harris@ontla.ola.org",
        address: "99 Wellesley St. West, Room 2540",
        city: "Toronto",
        province: "Ontario",
        postal: "M7A 1A2",
        website: "",
        latitude: 43.66317695682997,
        longitude: -79.38969992979776,
      },
    ],
    logo: "",
    profile: [],
  },
  {
    id: 3,
    contacts: [
      {
        company: "Ontario Association of Demolition Contractors",
        name: "Margaret Taylor",
        phone: "289-485-1017",
        fax: "",
        email: "mtaylor@oadc.ca",
        address: "70 Leek Crescent",
        city: "Richmond Hill",
        province: "Ontario",
        postal: "L4B 1H1",
        website: "",
        latitude: 43.85996233990328,
        longitude: -79.37897896038913,
      },
    ],
    logo: "/images/logos/associates/oadc.png",
    profile: [
      "The Ontario Association of Demolition Contractors (O.A.D.C.) is made up of employers who are actively engaged in the demolition and abatement industry and who have entered into a contract with the Labourer’s International Union of North America. The main functions of the O.A.D.C are to act as a bargaining agency for the members during negotiations, to keep the membership informed about issues that may impact their business and to lobby the government and its agencies on behalf of the industry.",
    ],
  },
  {
    id: 4,
    contacts: [
      {
        company: "University of Toronto",
        name: "Irfan Miraj",
        phone: "416-946-0101",
        fax: "416-978-6650",
        email: "irfan.miraj@utoronto.ca",
        address: "255 McCaul St., Level 4",
        city: "Toronto",
        province: "Ontario",
        postal: "M5T 1W7",
        website: "",
        latitude: 43.65856670945678,
        longitude: -79.39284312980008,
      },
    ],
    logo: "",
    profile: [],
  },
  {
    id: "Caskanette & Associates Consulting Engineers",
    contacts: [
      {
        company: "Caskanette & Associates Consulting Engineers",
        name: "Dave Giles",
        phone: " 519-745-5066",
        fax: "",
        email: "dave@caskanette.on.ca",
        address: "290 King Street East",
        city: "Kitchener",
        province: "Ontario",
        postal: "N2G 2L3",
        website: "",
        latitude: 43.44787839999999,
        longitude: -80.48412124232941,
      },
    ],
    logo: "/images/logos/associates/caskanette.jpeg",
    profile: [
      "Caskanette &amp; Associates Consulting Engineers specializes in forensic engineering and environmental services for insurance and legal clientele. With our extensive training and experience, from the site investigation through to testimony at trial, we can assure you that the high quality of service you need is the only kind we provide.",
      "Our team includes Civil, Mechanical, Materials, Electrical, and Structural Engineers, as well as Environmental and Building Science Experts. We have over 25 years of experience in site investigation, litigation, and design of solutions for complex problems.",
    ],
  },
];
