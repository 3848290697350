import Hero from "../../../components/Hero/Hero";
import RadonNav from "../RadonNav/RadonNav";
import "./RadonResources.scss";

import Action from "../../../assets/radon/TAOR-Logo.png";
import Who from "../../../assets/radon/who-logo.png";
import Map from "../../../assets/radon/map.jpeg";
import Map2 from "../../../assets/radon/map2.jpeg";

function RadonResources() {
  return (
    <>
      <Hero title="Ranon" subtitle="Resources" />
      <RadonNav />
      <div className="radon-resources">
        <div>
          <h3 className="radon-resources__subheader">
            Ontario Building Code Requires Radon Resistant Construction
          </h3>
          <ul className="radon-resources__list">
            <li className="radon-resources__list-item">
              <a className="radon-resources__link">
                Presentation by Bruce Decker, David Innes and Scott Cryer to the
                Toronto Construction Association (April 2019) (dead link)
              </a>
            </li>
            <li className="radon-resources__list-item">
              <a className="radon-resources__link">
                Ontario Building Code 2012 Radon Clauses (dead link)
              </a>
            </li>
            <li className="radon-resources__list-item">
              <a
                className="radon-resources__link"
                href="https://cfpub.epa.gov/si/si_public_record_Report.cfm?Lab=NRMRL&dirEntryID=126984"
                target="_blank"
                rel="noreferrer"
              >
                EPA 625-R-92-016 Radon Prevention In The Design And Construction
                Of Schools And Other Large Buildings
              </a>
              {` (Third Printing With Addendum June 1994)`}
            </li>
          </ul>
        </div>

        <div>
          <h3 className="radon-resources__subheader">
            Radon Resistant New Construction
          </h3>

          <ul className="radon-resources__list">
            <li className="radon-resources__list-item">
              Seek a C-NRPP professional certified in Controlling Radon In New
              Canadian Homes (CRNCH): <br />
              <a
                className="radon-resources__link"
                href="https://c-nrpp.ca/find-a-professional/"
              >
                https://c-nrpp.ca/find-a-professional/
              </a>
            </li>

            <li className="radon-resources__list-item">
              <a
                className="radon-resources__link"
                href="https://publications.gc.ca/site/eng/9.882605/publication.html"
                target="_blank"
                rel="noreferrer"
              >
                Radon control options for new construction in low-rise
                residential buildings
              </a>
            </li>

            <li className="radon-resources__list-item">
              Some helpful links:
              <ul className="radon-resources__list">
                <li className="radon-resources__list-item">
                  <a
                    className="radon-resources__link"
                    href="https://joneakes.com/learning-curve/147-radon-terminology"
                  >
                    https://joneakes.com/learning-curve/147-radon-terminology
                  </a>
                </li>

                <li className="radon-resources__list-item">
                  <a
                    className="radon-resources__link"
                    href="https://joneakes.com/learning-curve/149-sealing-against-soil-gas-entry-into-the-house
"
                  >
                    https://joneakes.com/learning-curve/149-sealing-against-soil-gas-entry-into-the-house
                  </a>
                </li>

                <li className="radon-resources__list-item">
                  <a
                    className="radon-resources__link"
                    href="https://joneakes.com/learning-curve/148-drawing-soil-gas-from-under-the-slab
"
                  >
                    https://joneakes.com/learning-curve/148-drawing-soil-gas-from-under-the-slab
                  </a>
                </li>

                <li className="radon-resources__list-item">
                  <a
                    className="radon-resources__link"
                    href="https://joneakes.com/learning-curve/150-the-science-of-passive-stack-draw
"
                  >
                    https://joneakes.com/learning-curve/150-the-science-of-passive-stack-draw
                  </a>
                </li>

                <li className="radon-resources__list-item">
                  <a
                    className="radon-resources__link"
                    href="https://joneakes.com/learning-curve/151-radon-planning-starts-at-the-discharge-point
"
                  >
                    https://joneakes.com/learning-curve/151-radon-planning-starts-at-the-discharge-point
                  </a>
                </li>
              </ul>
              <li className="radon-resources__list-item">
                <a
                  className="radon-resources__link"
                  href="https://webstore.ansi.org/standards/aarst/ansiaarstcc10002018"
                  target="_blank"
                  rel="noreferrer"
                >
                  ANSI/AARST CC-1000-2018 Soil Gas Control Systems In New
                  Construction Of Buildings
                </a>
              </li>
              <li className="radon-resources__list-item">
                Canadian General Standards Board (CGSB)
                <ul className="radon-resources__list">
                  <li className="radon-resources__list-item">
                    <a
                      className="radon-resources__link"
                      href="https://www.carst.ca/resources/Documents/CGSB-149.11-2015CD-01.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Radon control options for new construction in low rise
                      residential buildings
                    </a>
                  </li>
                </ul>
              </li>
            </li>
          </ul>
        </div>

        <div>
          <h3 className="radon-resources__subheader">
            Radon Mitigation in Existing Buildings
          </h3>
          <ul className="radon-resources__list">
            <li className="radon-resources__list-item">
              Health Canada Residential Mitigations Guidelines
            </li>
            <ul className="radon-resources__list">
              <li className="radon-resources__list-item">
                <a
                  className="radon-resources__link"
                  href="https://www.canada.ca/en/health-canada/services/environmental-workplace-health/reports-publications/radiation/radon-reduction-guide-canadians-health-canada-2013.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Radon - Reduction Guide for Canadians
                </a>
              </li>
              <li className="radon-resources__list-item">
                <a
                  className="radon-resources__link"
                  href="https://www.canada.ca/en/health-canada/services/environmental-workplace-health/reports-publications/radiation/reducing-radon-levels-existing-homes-canadian-guide-professional-contractors-health-canada-2010.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Reducing Radon Levels in Existing Homes: A Canadian Guide for
                  Professional Contractors
                </a>
              </li>
            </ul>

            <li className="radon-resources__list-item">
              Canadian General Standards Board (CGSB)
            </li>
            <ul className="radon-resources__list">
              <li className="radon-resources__list-item">
                <a
                  className="radon-resources__link"
                  href="https://carst.ca/resources/Documents/P29-149-012-2017-eng.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Radon mitigation options for existing low-rise residential
                  building
                </a>
              </li>
            </ul>

            <li className="radon-resources__list-item">
              <a
                className="radon-resources__link"
                href="https://www.youtube.com/watch?v=1xRcEVsV93Y"
                target="_blank"
                rel="noreferrer"
              >
                Tarion Radon Warranty Claim - up to 7 years post construction
              </a>
            </li>
          </ul>
        </div>

        <div>
          <h3 className="radon-resources__subheader">
            Radon Legislation and Guidance
          </h3>

          <ul className="radon-resources__list">
            <li className="radon-resources__list-item">
              Canadian Environmental Law Association (CELA)
            </li>
            <ul className="radon-resources__list">
              <li className="radon-resources__list-item">
                {`Canadian Environmental Law Association: `}
                <a
                  className="radon-resources__link"
                  href="https://cela.ca/wp-content/uploads/2019/07/Radon-Report-Excerpted-Executive-Summary-and-Table-of-Contents_0.pdf"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Radon in Indoor Air: A Review of Policy and Law in Canada
                </a>
              </li>
              <li className="radon-resources__list-item">
                {`Canadian Environmental Law Association: `}
                <a
                  className="radon-resources__link"
                  href="https://cela.ca/wp-content/uploads/2019/07/Radon-Policy-Scan-Full-Rept-with-Appendices_0.pdf"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Environmental Scan of Radon Law and Policy: Best Practices in
                  Canada and the European Union
                </a>
              </li>
            </ul>

            <li className="radon-resources__list-item">
              <a
                className="radon-resources__link"
                href="https://laws.justice.gc.ca/Search/Search.aspx?txtS3archA11=radon&txtT1tl3=%22Canada+Occupational+Health+and+Safety+Regulations%22&h1ts0n1y=0&ddC0nt3ntTyp3=Regulations"
                target={"_blank"}
                rel="noreferrer"
              >
                Federal Regulated Workplaces
              </a>
            </li>
            <li className="radon-resources__list-item">
              Provincial and Territorial Regulated Workplaces
            </li>
            <ul className="radon-resources__list">
              <li className="radon-resources__list-item">
                {`Canadian Environmental Law Association: `}
                <a
                  className="radon-resources__link"
                  href="https://cela.ca/wp-content/uploads/2019/07/Radon-Policy-Scan-Full-Rept-with-Appendices_0.pdf"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Environmental Scan of Radon Law and Policy: Best Practices in
                  Canada and the European Union
                </a>
              </li>
            </ul>
            <li className="radon-resources__list-item">
              <a
                className="radon-resources__link"
                href="https://www.canada.ca/en/health-canada/services/publications/health-risks-safety/canadian-guidelines-management-naturally-occurring-radioactive-materials.html"
                target={"_blank"}
                rel="noreferrer"
              >
                Guideline on Naturally Occurring Radioactive Materials (NORM)
              </a>
            </li>
            <li className="radon-resources__list-item">
              <a
                className="radon-resources__link"
                href="https://www.ontario.ca/page/radon-workplace"
                target={"_blank"}
                rel="noreferrer"
              >
                Ontario Ministry of Labour - Radon in the workplace
              </a>
            </li>
          </ul>
        </div>

        <div>
          <h3 className="radon-resources__subheader">Resources</h3>
          <ul className="radon-resources__list">
            <li className="radon-resources__list-item">
              {`Health Canada Radon - `}
              <a
                className="radon-resources__link"
                href="https://www.canada.ca/en/health-canada/services/health-risks-safety/radiation/radon.html"
                target="_blank"
                rel="noreferrer"
              >
                General
              </a>
            </li>
            <li className="radon-resources__list-item">
              {`Health Canada Radon - `}
              <a
                className="radon-resources__link"
                href="https://www.canada.ca/en/health-canada/services/publications/health-risks-safety/guide-radon-measurements-residential-dwellings.html"
                target="_blank"
                rel="noreferrer"
              >
                Guide for Radon Measurements in Residential Dwellings (Homes)
              </a>
            </li>
            <li className="radon-resources__list-item">
              {`Health Canada Radon - `}
              <a
                className="radon-resources__link"
                href="https://www.canada.ca/en/health-canada/services/environmental-workplace-health/reports-publications/radiation/guide-radon-measurements-public-buildings-schools-hospitals-care-facilities-detention-centres.html"
                target="_blank"
                rel="noreferrer"
              >
                Guide for Radon Measurements in Public Buildings Workplaces,
                Schools, Day Cares, Hospitals, Care Facilities, Correctional
                Centres
              </a>
            </li>
          </ul>
        </div>

        <div>
          <h3 className="radon-resources__subheader">
            Radon in Real Estate Transactions
          </h3>

          <ul className="radon-resources__list">
            <li className="radon-resources__list-item">
              {`Health Canada Radon - `}
              <a
                className="radon-resources__link"
                href="https://www.canada.ca/en/health-canada/services/publications/health-risks-safety/radon-real-estate.html"
                target="_blank"
                rel="noreferrer"
              >
                Real Estate
              </a>
            </li>

            <li className="radon-resources__list-item">
              <a
                className="radon-resources__link"
                href="https://carst.ca/resources/Documents/CARST%20Guideline%20for%20Conducting%20a%20Radon%20Screening%20Assessment%20as%20Part%20of%20a%20Real%20Estate%20Transaction%20of%20a%20Residential%20Dwelling%20in%20Canada%20-%20Final%202018.pdf"
                target="_blank"
                rel="noreferrer"
              >
                CARST Guideline for Conducting a Radon Screening Assessment as
                Part of a Real Estate Transaction of a Residential Dwelling in
                Canada
              </a>
            </li>

            <img
              className="radon-resources__image"
              src={Action}
              alt="Carst Logo"
            />

            <li className="radon-resources__list-item">
              <a
                className="radon-resources__link"
                href="https://www.canada.ca/en/health-canada/services/health-risks-safety/radiation/radon/take-action-on-radon.html"
                target="_blank"
                rel="noreferrer"
              >
                Take Action on Radon (TAOR)
              </a>
            </li>

            <img className="radon-resources__image" src={Who} alt="WHO logo" />

            <li className="radon-resources__list-item">
              <a
                className="radon-resources__link"
                href="https://www.who.int/news-room/fact-sheets/detail/radon-and-health"
                target="_blank"
                rel="noreferrer"
              >
                World Health Organization (WHO)
              </a>
            </li>
          </ul>
        </div>

        <div>
          <h3 className="radon-resources__subheader">Radon Prone Areas</h3>

          <ul className="radon-resources__list">
            <li className="radon-resources__list-item">
              <a
                className="radon-resources__link"
                href="https://www.ccohs.ca/oshanswers/phys_agents/radon.html"
                target="_blank"
                rel="noreferrer"
              >
                Canadian Centre for Occupational Health and Safety - Radon in
                Buildings
              </a>
            </li>

            <li className="radon-resources__list-item">
              <a
                className="radon-resources__link"
                href="https://www.canada.ca/en/health-canada/services/environmental-workplace-health/reports-publications/radiation/cross-canada-survey-radon-concentrations-homes-final-report-health-canada-2012.html"
                target="_blank"
                rel="noreferrer"
              >
                Health Canada Cross Country Radon Study.
              </a>
              {` The survey's results should not be used as a tool to determine radon risk potential or whether or not to test a home or building for radon.`}
            </li>

            <li className="radon-resources__list-item">
              <a
                className="radon-resources__link"
                href="https://c-nrpp.ca/radon-map/?center[]=58.81374171570782&center[]=-94.482421875&zoom=4"
                target="_blank"
                rel="noreferrer"
              >
                C-NRPP Interactive Radon Map
              </a>
            </li>

            <li className="radon-resources__list-item">
              <a
                className="radon-resources__link"
                href="https://radoncorp.com/mapping"
                target="_blank"
                rel="noreferrer"
              >
                Radon Potential Maps
              </a>
            </li>

            <img className="radon-resources__image" src={Map} alt="Radon Map" />

            <img
              className="radon-resources__image"
              src={Map2}
              alt="Radon Map 2"
            />

            <li className="radon-resources__list-item">
              <a className="radon-resources__link">
                Take Action on Radon (TAOR)
              </a>
            </li>

            <li className="radon-resources__list-item">
              <a className="radon-resources__link">
                World Health Organization (WHO)
              </a>
            </li>
          </ul>
        </div>

        <div>
          <h3 className="radon-resources__subheader">Informative Videos</h3>

          <ul className="radon-resources__list">
            <li className="radon-resources__list-item">
              Introduction to Radon Videos:
            </li>

            <ul className="radon-resources__list">
              <li className="radon-resources__list-item">
                <a
                  className="radon-resources__link"
                  href="https://www.youtube.com/watch?v=cPkbCGHwt9Y"
                  target="_blank"
                  rel="noreferrer"
                >
                  Radon In 60 Seconds
                </a>
              </li>

              <li className="radon-resources__list-item">
                <a
                  className="radon-resources__link"
                  href="https://www.youtube.com/watch?v=tEcoPgA-FE4"
                  target="_blank"
                  rel="noreferrer"
                >
                  Radon: Is it in your home?
                </a>
              </li>

              <li className="radon-resources__list-item">
                <a
                  className="radon-resources__link"
                  href="https://www.youtube.com/watch?v=2IVaFMYi_mg"
                  target="_blank"
                  rel="noreferrer"
                >
                  Radon Mitigation
                </a>
              </li>

              <li className="radon-resources__list-item">
                <a
                  className="radon-resources__link"
                  href="https://www.youtube.com/watch?v=aYu71niQvJk"
                  target="_blank"
                  rel="noreferrer"
                >
                  Radon Testing
                </a>
              </li>

              <li className="radon-resources__list-item">
                <a
                  className="radon-resources__link"
                  href="https://www.youtube.com/watch?v=SY3RxpgxO9s"
                  target="_blank"
                  rel="noreferrer"
                >
                  Radon Gas #1: What is it?
                </a>
              </li>

              <li className="radon-resources__list-item">
                <a
                  className="radon-resources__link"
                  href="https://www.youtube.com/watch?v=cSKa7xH2lhY"
                  target="_blank"
                  rel="noreferrer"
                >
                  Radon Gas #2: How it causes damage?
                </a>
              </li>

              <li className="radon-resources__list-item">
                <a
                  className="radon-resources__link"
                  href="https://www.youtube.com/watch?v=_kTnisl822U"
                  target="_blank"
                  rel="noreferrer"
                >
                  Radon Gas #3: How we are exposed to it & what to do about it
                </a>
              </li>
            </ul>

            <li className="radon-resources__list-item">Radon Health:</li>

            <ul className="radon-resources__list">
              <li className="radon-resources__list-item">
                <a
                  className="radon-resources__link"
                  href="https://www.youtube.com/watch?v=CLTZ5l7mdn8"
                  target="_blank"
                  rel="noreferrer"
                >
                  Mark's Story: The Impact of Radon Exposure
                </a>
              </li>
              <li className="radon-resources__list-item">
                <a
                  className="radon-resources__link"
                  href="https://www.youtube.com/watch?v=cIsZAecaUkQ"
                  target="_blank"
                  rel="noreferrer"
                >
                  Virginia's Story - The Impact of Radon Exposure
                </a>
              </li>
              <li className="radon-resources__list-item">
                <a
                  className="radon-resources__link"
                  href="https://www.youtube.com/watch?v=sXOkdvwma4I"
                  target="_blank"
                  rel="noreferrer"
                >
                  Janet's Story - Test Your Home
                </a>
              </li>
            </ul>
          </ul>
        </div>
      </div>
    </>
  );
}
export default RadonResources;
