import React from "react";
import { GoogleMap, useLoadScript, Marker, InfoWindow } from "@react-google-maps/api";
import "./Map.scss";
import mapStyle from "./mapStyles";

const libraries = ["places"];

const mapContainerStyle = {
  height: "100%",
  width: "100%",
};

const center = {
  lat: 43.6532,
  lng: -79.3832,
};

const options = {
  styles: mapStyle,
  zoomControl: true,
  keyboardShortcuts: false,
  disableDefaultUI: true,
};

function Map({ membersList, selected, setSelected }) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyBbMVZGVsI56nplcxQjnnhWNvedq4JG9LY",
    libraries,
  });
  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";

  return (
    <div className="map">
      <GoogleMap options={options} mapContainerStyle={mapContainerStyle} onLoad={onMapLoad} center={center} zoom={9}>
        {membersList.map((member, i) => {
          return member.contacts.map((contact, j) => {
            return (
              <Marker
                key={`${i} ${j}`}
                position={{ lat: contact.latitude, lng: contact.longitude }}
                onClick={() => {
                  setSelected(contact);
                }}
              />
            );
          });
        })}

        {selected && (
          <InfoWindow
            position={{ lat: selected.latitude, lng: selected.longitude }}
            onCloseClick={() => {
              setSelected(null);
            }}
          >
            <div className="map__card">
              <h5 className="map__title">{selected.company}</h5>
              <p className="map__text">
                <span className="map__text--bold">Contact:</span> {selected.name}
              </p>

              <a className="map__text" href={`mailto:${selected.email}`}>
                <span className="map__text--bold">Email:</span> {selected.email}
              </a>

              {selected.secondaryEmail && (
                <p className="map__text">
                  <span className="map__text--bold">Secondary Contact:</span> {selected.secondaryName}
                </p>
              )}

              {selected.secondaryEmail && (
                <a className="map__text" href={`mailto:${selected.email}`}>
                  <span className="map__text--bold">Secondary Email:</span> {selected.secondaryEmail}
                </a>
              )}

              <a
                className="map__text"
                href={`https://www.google.com/maps/dir/?api=1&destination=${selected.address.replace(" ", "+")}+${selected.city}`}
              >
                <span className="map__text--bold">Address:</span> {selected.address} {selected.city} {selected.postal}
              </a>

              <p className="map__text">
                <span className="map__text--bold">Phone #:</span> {selected.phone}
              </p>
              {selected.fax && (
                <p className="map__text">
                  <span className="map__text--bold">Fax #:</span> {selected.fax}
                </p>
              )}
              {selected.website && (
                <a className="map__text" target="_blank" rel="noreferrer" href={`http://${selected.website}`}>
                  <span className="map__text--bold">Website: </span> {selected.website}
                </a>
              )}
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </div>
  );
}

export default Map;
