import { Link } from "react-router-dom";
import "./SecondaryNav.scss";

function SecondaryNav() {
  return (
    <div className="secondary-nav">
      <ul className="secondary-nav__list">
        <Link className="nav__link" to="/covid-19">
          <li className="secondary-nav__list-item">Covid-19</li>
        </Link>
        <Link className="nav__link" to="/radon">
          <li className="secondary-nav__list-item">Radon</li>
        </Link>
        <Link className="nav__link" to="/drug-opioid">
          <li className="secondary-nav__list-item">Drug/Opioid</li>
        </Link>
        <Link className="nav__link" to="/job-postings">
          <li className="secondary-nav__list-item">Job Postings</li>{" "}
        </Link>
        <Link className="nav__link" to="/photos">
          <li className="secondary-nav__list-item">Photos</li>
        </Link>
        <Link className="nav__link" to="/contact-us">
          <li className="secondary-nav__list-item">Contact Us</li>
        </Link>
      </ul>
    </div>
  );
}
export default SecondaryNav;
