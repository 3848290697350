import React from "react";
import { useState } from "react";
import "./PhotoPopUp.scss";
import close from "../../assets/icons/x-close.svg";
import closeHighlight from "../../assets/icons/close-hover.svg";

import back from "../../assets/icons/arrow-back-default.svg";
import forward from "../../assets/icons/arrow-forward-default.svg";
import forwardHighlight from "../../assets/icons/arrow-forward-hover.svg";
import backHighlight from "../../assets/icons/arrow-back-hover.svg";

function PhotoPopUp({ gallery, subtitle, activeImage, setActiveImage }) {
  const [leftHover, setLeftHover] = useState(false);
  const [rightHover, setRightHover] = useState(false);
  const [closeHover, setCloseHover] = useState(false);
  return (
    <div className="popup">
      <div className="popup__overlay">
        <div className="popup__active">
          <img
            src={leftHover ? backHighlight : back}
            onMouseOver={() => {
              setLeftHover(true);
            }}
            onMouseOut={() => {
              setLeftHover(false);
            }}
            className={`popup__left ${leftHover ? "popup__left--animate" : ""}`}
            onClick={() => {
              if (activeImage === gallery.length - 1) {
                setActiveImage(0);
              } else {
                setActiveImage(activeImage + 1);
              }
            }}
          />
          <img
            src={rightHover ? forwardHighlight : forward}
            onMouseOver={() => {
              setRightHover(true);
            }}
            onMouseOut={() => {
              setRightHover(false);
            }}
            className={`popup__right ${
              rightHover ? "popup__right--animate" : ""
            }`}
            onClick={() => {
              if (activeImage === gallery.length - 1) {
                setActiveImage(0);
              } else {
                setActiveImage(activeImage + 1);
              }
            }}
          />

          <div className="popup__close-wrapper">
            <img
              src={closeHover ? closeHighlight : close}
              onMouseOver={() => {
                setCloseHover(true);
              }}
              onMouseOut={() => {
                setCloseHover(false);
              }}
              className="popup__close"
              alt=""
              onClick={() => {
                setActiveImage(null);
              }}
            ></img>
            <div className="popup__controls">
              <img
                className="popup__active-image"
                src={gallery[activeImage].photo}
                alt="Gallery Image"
              />
            </div>
            <div className="popup__info">
              <p className="popup__title">{subtitle}</p>
              <p className="popup__number">{`${activeImage + 1} of ${
                gallery.length
              }`}</p>
            </div>
          </div>
          {/* <img
            src={back}
            className="popup__left"
            onClick={() => {
              if (activeImage === 0) {
                setActiveImage(gallery.length - 1);
              } else {
                setActiveImage(activeImage - 1);
              }
            }}
          ></img>

          <img
            src={forward}
            className="popup__right"
            onClick={() => {
              if (activeImage === gallery.length - 1) {
                setActiveImage(0);
              } else {
                setActiveImage(activeImage + 1);
              }
            }}
          ></img> */}
        </div>
      </div>
    </div>
  );
}

export default PhotoPopUp;
