import React from "react";
import "../Gallery/Gallery.scss";
import SingleGallery from "./SingleGallery/SingleGallery";

import { Link } from "react-router-dom";

function Gallery({ year, data }) {
  return (
    <div className="gallery">
      <h2 className="gallery__year">{year}</h2>
      <div className="gallery__container">
        {data &&
          data.map((cover) => {
            return (
              <div className="gallery__cover">
                <Link to={cover.link} className="gallery__link">
                  <img className="gallery__image" src={cover.cover} loading="lazy"></img>
                  <h3 className="gallery__title">{cover.title}</h3>
                </Link>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default Gallery;
