import Hero from "../../components/Hero/Hero";
import "./Covid19Page.scss";
import Pathogen from "../../assets/pdfs/Pathogen.pdf";

function Covid19Page() {
  return (
    <>
      <Hero title="Covid-19" subtitle="Resources Page" />
      <div className="covid">
        <p className="covid__text">
          As the thought leaders in the environmental response, abatement and
          remediation industries EACC is committed to providing the latest
          industry resources that will allow organizations to remain safe,
          effective and profitable during this time.
        </p>
        <p className="covid__text">
          Below is a collection of documents and links available to help you
          better manage the current pandemic situation. We will continue to
          update this page as we move through this unprecedented time.
        </p>
        <p className="covid__text">
          Feel to reach out to us at{" "}
          <a className="covid__link" href="">
            info@eaccan.com
          </a>
          with questions or additional information resources that should be
          added here.
        </p>

        <ul>
          <li className="covid__list-item">
            <a
              className="covid__link"
              href="https://www.ontario.ca/page/guide-developing-your-covid-19-workplace-safety-plan"
              target="_blank"
              rel="noreferrer"
            >
              Ontario Government's Toolkit for Employers to Create Safer Working
              Environments
            </a>
          </li>
          <li className="covid__list-item">
            <a className="covid__link" href={Pathogen}>
              EACC Emerging and Existing Pathogen Cleaning - Best Practices for
              Environmental Professional Services (version 2 2020)
            </a>
          </li>
          {/* <li className="covid__list-item">
            <a className="covid__link" href="">
              Environmental Information Association Blog
            </a>
          </li> */}
          <li className="covid__list-item">
            <a
              className="covid__link"
              href="https://www.aiha.org/public-resources/consumer-resources/aiha-covid-19-pandemic-efforts"
              target="_blank"
              rel="noreferrer"
            >
              American Industrial Hygiene Association (AIHA) Coronavirus
              Resource Centre
            </a>
          </li>
          <li className="covid__list-item">
            <a
              className="covid__link"
              href="https://www.envistaforensics.com/knowledge-center/"
              target="_blank"
              rel="noreferrer"
            >
              Envista Forensics: The Harmful After-Effects of Disinfecting
              Fogging Agents on Electronic and Electrical Equipment
            </a>
          </li>
        </ul>
      </div>
    </>
  );
}
export default Covid19Page;
