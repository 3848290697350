import React from "react";
import "../SingleGallery/SingleGallery.scss";

import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Hero from "../../Hero/Hero";
import PhotoPopUp from "../../PhotoPopUp/PhotoPopUp";

// 2024 galleries
import golf2024 from "../../../galleries/2024/2024-eacc-golf-tournament";

import golf2023 from "../../../galleries/2023/2023-eacc-golf-tournament";

import agm2023 from "../../../galleries/2023/2023-eacc-agm";
import Asbestos2023 from "../../../galleries/2023/2023-asbestos-awareness-training";

// 2022 Galleries
import golf2022 from "../../../galleries/2022/2022-eacc-golf-tournament";

// 2021 Galleries
import golf2021 from "../../../galleries/2021/2021-eacc-golf-tournament";
import agm2021 from "../../../galleries/2021/2021-eacc-agm";

// 2020 Galleries
import golf2020 from "../../../galleries/2020/2020-eacc-golf-tournament.json";
import agm2020 from "../../../galleries/2020/2020-eacc-agm.json";
import axe2020 from "../../../galleries/2020/2020-eacc-axe.json";

// 2019 Galleries
import golf2019 from "../../../galleries/2019/2019-eacc-golf-tournament.json";
import agm2019 from "../../../galleries/2019/2019-eacc-agm.json";

// 2018 Galleries
import golf2018 from "../../../galleries/2018/2018-eacc-golf-tournament.json";
import pub2018 from "../../../galleries/2018/2018-eacc-pub-night.json";
import mastermind2018 from "../../../galleries/2018/2018-eacc-womens-mastermind.json";

// 2017 Galleries
import golf2017 from "../../../galleries/2017/2017-eacc-golf-tournament";

// 2016 Galleries
import golf2016 from "../../../galleries/2016/2016-eacc-golf-tournament";
import agm2016 from "../../../galleries/2016/2016-eacc-agm";
import pubnight2016 from "../../../galleries/2016/2016-pub-night";

// 2015 Galleries
import golf2015 from "../../../galleries/2015/2015-eacc-golf-tournament";
import agm2015 from "../../../galleries/2015/2015-eacc-agm";

// 2014 Galleries
import golf2014 from "../../../galleries/2014/2014-eacc-golf-tournament";
import women2014 from "../../../galleries/2014/2014-womens-inaugural-social";

// 2012 Galleries
import golf2012 from "../../../galleries/2012/2012-eacc-golf-tournament";

// 2009 Galleries
import golf2010 from "../../../galleries/2010/2010-eacc-golf-tournament";
import women2010 from "../../../galleries/2010/2010-womens-inaugural-social";

// 2009 Galleries
import golf2009 from "../../../galleries/2009/2009-eacc-golf-tournament";

// 2008 Galleries
import golf2008 from "../../../galleries/2008/2008-eacc-golf-tournament";

// 2006 Galleries
import golf2006 from "../../../galleries/2006/2006-eacc-golf-tournament";

// 2005 Galleries
import golf2005 from "../../../galleries/2005/2005-eacc-golf-tournament";

function SingleGallery({}) {
  const [data, setData] = useState("");
  const params = useParams();

  const [activeImage, setActiveImage] = useState(null);
  const activeImageHandler = (value) => {
    setActiveImage(value);
  };

  const yearCheck = () => {
    if (params.id === "2024-eacc-golf-tournament") {
      setData(...golf2024);
    }
    if (params.id === "2023-eacc-golf-tournament") {
      setData(...golf2023);
    }
    if (params.id === "2023-eacc-agm") {
      setData(...agm2023);
    }
    if (params.id === "2023-asbestos-awareness-training") {
      setData(...Asbestos2023);
    }
    if (params.id === "2022-eacc-golf-tournament") {
      setData(...golf2022);
    }
    if (params.id === "2021-eacc-golf-tournament") {
      setData(...golf2021);
    }
    if (params.id === "2021-eacc-agm") {
      setData(...agm2021);
    }
    if (params.id === "2020-eacc-golf-tournament") {
      setData(...golf2020);
    }
    if (params.id === "2020-eacc-agm") {
      setData(...agm2020);
    }
    if (params.id === "2020-eacc-axe-throw") {
      setData(...axe2020);
    }
    if (params.id === "2019-eacc-golf-tournament") {
      setData(...golf2019);
    }
    if (params.id === "2019-eacc-agm") {
      setData(...agm2019);
    }
    if (params.id === "2018-eacc-golf-tournament") {
      setData(...golf2018);
    }
    if (params.id === "2018-eacc-pub-night") {
      setData(...pub2018);
    }
    if (params.id === "2018-eacc-womens-mastermind") {
      setData(...mastermind2018);
    }
    if (params.id === "2017-eacc-golf-tournament") {
      setData(...golf2017);
    }

    if (params.id === "2016-eacc-golf-tournament") {
      setData(...golf2016);
    }

    if (params.id === "2016-eacc-agm") {
      setData(...agm2016);
    }

    if (params.id === "2016-pub-night") {
      setData(...pubnight2016);
    }

    if (params.id === "2015-eacc-golf-tournament") {
      setData(...golf2015);
    }

    if (params.id === "2015-eacc-agm") {
      setData(...agm2015);
    }

    if (params.id === "2014-eacc-golf-tournament") {
      setData(...golf2014);
    }

    if (params.id === "2014-womens-inaugural-social") {
      setData(...women2014);
    }

    if (params.id === "2012-eacc-golf-tournament") {
      setData(...golf2012);
    }

    if (params.id === "2010-womens-inaugural-social") {
      setData(...women2010);
    }

    if (params.id === "2010-eacc-golf-tournament") {
      setData(...golf2010);
    }

    if (params.id === "2009-eacc-golf-tournament") {
      setData(...golf2009);
    }

    if (params.id === "2008-eacc-golf-tournament") {
      setData(...golf2008);
    }

    if (params.id === "2006-eacc-golf-tournament") {
      setData(...golf2006);
    }

    if (params.id === "2005-eacc-golf-tournament") {
      setData(...golf2005);
    }
  };

  useEffect(() => {
    yearCheck();
  }, []);

  return (
    <>
      <Hero title={"Photos"} subtitle={data.subtitle} />
      <div className="single-gallery">
        {/* <div className="single-gallery__section">
        <h3>{data.hole[0].subheader}</h3>
        <ul className="single-gallery__sponsors">
          {data &&
            data.hole.map((sponsor) => {
              return (
                <li className="single-gallery__sponsor">{sponsor.sponsor}</li>
              );
            })}
        </ul>
        <h4>{data.message}</h4>
      </div> */}

        {data.info && data.info}

        <div className="single-gallery__gallery">
          {data &&
            data.photos &&
            data.photos.map((gallery, i) => {
              return (
                <div className="single-gallery__box">
                  <img
                    onClick={() => {
                      setActiveImage(i);
                    }}
                    className="single-gallery__photo"
                    src={gallery.photo}
                    loading="lazy"
                  ></img>
                </div>
              );
            })}
        </div>
        {activeImage != null && (
          <PhotoPopUp
            gallery={data.photos}
            subtitle={data.subtitle}
            activeImage={activeImage}
            setActiveImage={activeImageHandler}
          />
        )}
      </div>
    </>
  );
}

export default SingleGallery;
