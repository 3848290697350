export default [
  {
    info: (
      <div className="single-gallery__info">
        <p className="single-gallery__text">September 28, 2006</p>
        <p className="single-gallery__text">
          At the recent <strong>Environmental Abatement Council of Canada</strong> golf tournament held on Thursday, September 28, 2006 at Caledon
          Woods Golf Club, sixty persons enjoyed a great but “chilly” day on the links. Prior to golf, everyone enjoyed a BBQ lunch on the patio.
          After golf, members enjoyed cocktails and dinner. All golfers received an EACC cooler bag filled with goodies and a tote bag, compliments of
          Caledon Woods.
        </p>

        <p className="single-gallery__text">Thanks are extended to this year’s golf sponsors:</p>

        <p className="single-gallery__text single-gallery__text--bold">
          Hazmasters and Dupont Personal Protection: Lunch, Insect Repellent, Sunscreen, Golf Towel, Sleeve of Balls
        </p>

        <p className="single-gallery__text single-gallery__text--bold">Pinchin Environmental: Dinner Wine</p>

        <p className="single-gallery__text single-gallery__text--bold">Safety Express: Beer Cart, Dinner Wine, Hat and T-Shirt</p>

        <p className="single-gallery__text single-gallery__text--bold">Terrasan Environmental: Beer Cart, Dinner Wine</p>

        <p className="single-gallery__text single-gallery__text--bold">
          Hole sponsors: <br />
          Ferro Canada <br />
          I and I Construction <br />
          Ontario Insulation <br />
          MRA Abatement <br />
          Safety Express Ltd. <br />
          Wayne & Harold Smith Construction
        </p>

        <p className="single-gallery__text">
          Thanks are also extended to this year’s Golf Committee – Ian Henderson and Steve Fulford, for their assistance in organizing the event.
        </p>

        <p className="single-gallery__text">
          Don Pinchin, President of EACC, acted as Master of Ceremonies for the evening. He gave a special welcome to Evelyn Stefov from the Ministry
          of Labour. As well, he provided a brief overview of EACC activities.
        </p>

        <p className="single-gallery__text">
          From this year’s golf proceeds, EACC will be making a donation to the Richard Jaan Helmeste Memorial Award. Richard passed away from
          Mesothelioma in the spring of 2003. He was an occupational hygienist with the Ministry of Labour and was genuinely devoted to his work in
          health and safety.
        </p>

        <p className="single-gallery__text">
          <u>Winners included</u>:
        </p>

        <p className="single-gallery__text single-gallery__text--bold">
          Low Team: Shaun Fralic, Brian Holt, Luiz De Caravalho, Vikas Metha
          <br />
          Closest to the Pin (sponsored by Golfers Green): Luiz De Caravalho <br />
          Closest to the Pin: Andrew Cunningham <br />
          Longest Drive: Jason Ward <br />
          Closest to the Rope (2 Holes): Vikas Metha and Derek Hanna
          <br />
          Longest Putt (2 Holes): Warren Griffiths and Jay Inman <br />
          Grand Prize Winner (27″ colour TV): Mark Reinhardt
        </p>
      </div>
    ),
    subtitle: "2006 EACC Golf Tournament",
    photos: [
      {
        photo: "/images/PhotoGalleries/2006/2006-eacc-golf-tournament/2006-Golf-Tournament-01.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2006/2006-eacc-golf-tournament/2006-Golf-Tournament-02.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2006/2006-eacc-golf-tournament/2006-Golf-Tournament-03.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2006/2006-eacc-golf-tournament/2006-Golf-Tournament-04.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2006/2006-eacc-golf-tournament/2006-Golf-Tournament-05.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2006/2006-eacc-golf-tournament/2006-Golf-Tournament-06.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2006/2006-eacc-golf-tournament/2006-Golf-Tournament-07.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2006/2006-eacc-golf-tournament/2006-Golf-Tournament-08.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2006/2006-eacc-golf-tournament/2006-Golf-Tournament-09.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2006/2006-eacc-golf-tournament/2006-Golf-Tournament-10.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2006/2006-eacc-golf-tournament/2006-Golf-Tournament-11.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2006/2006-eacc-golf-tournament/2006-Golf-Tournament-12.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2006/2006-eacc-golf-tournament/2006-Golf-Tournament-13.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2006/2006-eacc-golf-tournament/2006-Golf-Tournament-14.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2006/2006-eacc-golf-tournament/2006-Golf-Tournament-15.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2006/2006-eacc-golf-tournament/2006-Golf-Tournament-16.jpg",
      },
    ],
  },
];
