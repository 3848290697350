import { Link } from "react-router-dom";
import { useState } from "react";
import "./DrugNav.scss";
import MobileDrugBurger from "../../../components/MobileDrugBurger/MobileDrugBurger";

import drugBurgerMenuIcon from "../../../../src/assets/icons/burger-green.svg";

function DrugNav() {
  const [drugBurgerActive, setDrugBurgerActive] = useState(false);

  return (
    <div className="drug-nav">
      <div className="drug-nav__list">
        <Link className="drug-nav__item" to="/drug-opioid">
          <p className="drug-nav__text">Drug/Opioid Home</p>
        </Link>
        <Link className="drug-nav__item" to="/drug-opioid-resources">
          <p className="drug-nav__text">Drug/Opioid Resources</p>
        </Link>
      </div>
      <div className="drug-nav__container">
        <img
          onClick={() => {
            setDrugBurgerActive(!drugBurgerActive);
          }}
          className="drug-nav__burger"
          src={drugBurgerMenuIcon}
          alt="burger menu"
        ></img>
      </div>
      {drugBurgerActive && (
        <MobileDrugBurger setDrugBurgerActive={setDrugBurgerActive} />
      )}
    </div>
  );
}
export default DrugNav;
