import "./Slider.scss";
import slide01 from "../../../assets/slideshow/EACC-slide01.jpg";
import slide02 from "../../../assets/slideshow/EACC-slide02.jpg";
import slide03 from "../../../assets/slideshow/EACC-slide03.jpg";
import slide04 from "../../../assets/slideshow/EACC-slide04.jpg";
import slide05 from "../../../assets/slideshow/EACC-slide05.jpg";
import slide06 from "../../../assets/slideshow/EACC-slide06.jpg";
import slide07 from "../../../assets/slideshow/EACC-slide07.jpg";
import slide08 from "../../../assets/slideshow/EACC-slide08.jpg";
import slide09 from "../../../assets/slideshow/EACC-slide09.jpg";
import slide10 from "../../../assets/slideshow/EACC-slide10.jpg";
import slide11 from "../../../assets/slideshow/EACC-slide11.jpg";
import slide12 from "../../../assets/slideshow/EACC-slide12.jpg";
import { useEffect, useRef, useState } from "react";
import PhotoPopUp from "../../PhotoPopUp/PhotoPopUp";
import back from "../../../assets/icons/arrow-back-default.svg";
import forward from "../../../assets/icons/arrow-forward-default.svg";

function Slider() {
  const gallery = [
    { photo: slide01 },
    { photo: slide02 },
    { photo: slide03 },
    { photo: slide04 },
    { photo: slide05 },
    { photo: slide06 },
    { photo: slide07 },
    { photo: slide08 },
    { photo: slide09 },
    { photo: slide10 },
    { photo: slide11 },
    { photo: slide12 },
  ];
  const [index, setIndex] = useState(0);
  const [hovered, setHovered] = useState(false);
  const [activeImage, setActiveImage] = useState(null);

  let intervalRef = useRef();

  const startTimer = () => {
    intervalRef.current = setInterval(() => {
      if (index === 12) {
        setIndex(0);
      } else {
        setIndex((prevIndex) => (prevIndex === gallery.length - 1 ? 0 : prevIndex + 1));
      }
    }, 3000);
  };

  useEffect(() => {
    startTimer();
    return () => clearInterval(intervalRef);
  }, []);

  return (
    <div className="slider">
      <div
        className="slider__controls"
        onMouseOver={() => {
          setHovered(true);
          clearInterval(intervalRef.current);
        }}
        onMouseOut={() => {
          setHovered(false);
          startTimer();
        }}
      >
        {hovered && (
          <img
            className="slider__left"
            src={back}
            onClick={() => {
              setIndex((prevIndex) => (prevIndex === 0 ? 11 : index - 1));
            }}
          />
        )}

        <img
          key={index}
          className="slider__image"
          src={gallery[index].photo}
          alt=""
          onClick={() => {
            setActiveImage(index);
            clearInterval(intervalRef.current);
          }}
        />
        {hovered && (
          <img
            className="slider__right"
            src={forward}
            onClick={() => {
              setIndex((prevIndex) => (prevIndex === 11 ? 0 : index + 1));
            }}
          />
        )}
      </div>

      {activeImage != null && <PhotoPopUp gallery={gallery} activeImage={activeImage} setActiveImage={setActiveImage} />}
    </div>
  );
}
export default Slider;
