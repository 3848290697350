import Hero from "../../components/Hero/Hero";
import "./BoardMinutesPage.scss";
import boardMinutes from "../../board-pdfs";

function BoardMinutesPage() {
  return (
    <>
      <Hero title="Board Meeting Minutes" subtitle="Executive Summary"></Hero>
      <div className="board-minutes">
        <p className="board-minutes__text">Download the Board Meeting Minutes (Executive Summary) in PDF format:</p>

        {boardMinutes &&
          boardMinutes.map((event) => {
            return (
              <a className="board-minutes__button" href={event.pdf}>
                {event.date}
              </a>
            );
          })}
      </div>
    </>
  );
}
export default BoardMinutesPage;
