export default [
  {
    info: (
      <div className="single-gallery__info">
        <p className="single-gallery__text">September 15, 2014</p>
        <p className="single-gallery__text">
          This year’s annual golf tournament was held on Wednesday, September 17, 2014 at Wooden Sticks Golf Club in Uxbridge. Members enjoyed a great
          day of golf and networking. Lunch was offered at 11:00am, followed by a 1:00pm shotgun start. The weather was warm with a mixture of sun and
          cloud. Cocktails and a deluxe dinner buffet followed golf along with prize presentations and a silent auction. All golfers received a pair
          of golf shoes and golf bag, compliments of EACO and a choice of a golf hat or towel, compliments of Wooden Sticks.
        </p>
        <p className="single-gallery__text">Special thanks were extended to the following sponsors:</p>
        <p className="single-gallery__text single-gallery__text--bold">
          Cart Sponsor: Hazmasters <br />
          Platinum Sponsor: Abbot Environmental Ltd.
          <br />
          Gold Sponsors – Lunch: Abatement Technologies
          <br />
          Silver Sponsors – Dinner Wine: Stantec Consulting Ltd.
          <br />
          Silver Sponsor – Putting Contest: Sporometrics
          <br />
          Driving Range Sponsor: Tri-Phase Group
          <br />
          Gift Sponsor: Alliance Creative Marketing
        </p>
        <p className="single-gallery__text">Thanks were also extended to our hole sponsors:</p>
        <p className="single-gallery__text">
          Alliance Environmental & Abatement Contractors Inc. <br />
          Caliber Environmental <br />
          CCI Group Inc. <br />
          ECOH <br />
          EMSL Canada Inc. <br />
          Envirosafe Inc. <br />
          FERRO Canada Inc. <br />
          GB Environmental Services <br />
          H&S Specialties Inc. <br />
          I & I Construction Services Limited <br />
          KG: Services <br />
          McGowan Insulations Ltd. <br />
          Ontario Insulation <br />
          Pinchin Environmental Ltd. <br />
          Quantum Murray LP <br />
          R.C. White Ltd. <br />
          Stantec Consulting Ltd.
        </p>
        <p className="single-gallery__text">This year’s silent auction raised $1,235.00 – a donation will be made to a charity of EACO’s choice.</p>
        <p className="single-gallery__text">
          Thanks to all that attended and to Ian Henderson and Steve Fulford for their assistance in organizing the event.
        </p>
        <p className="single-gallery__text single-gallery__text--bold">Contest Winners included:</p>
        <p className="single-gallery__text">
          Closest to the Rope (Hole 10) – Tracy Graham
          <br />
          Closest to the Rope (Hole 18) – Ian Bartels
        </p>
        <p className="single-gallery__text">
          Closest to the Pin – Ladies (Hole 8) – Catherine Mills
          <br />
          Closest to the Pin – Men (Hole 17) – Rob Lovegrove
        </p>

        <p className="single-gallery__text">
          Longest Drive – Ladies (Hole 9) – Catherine Mills
          <br />
          Longest Drive – Men (Hole 9) – Brian Edwardson
        </p>

        <p className="single-gallery__text">
          Longest Putt (Hole 4) – Terry DeMedeiros <br />
          Longest Putt (Hole 18) – Rob Lovegrove
        </p>

        <p className="single-gallery__text">
          <strong>Beat the Pro Competition:</strong> Don Pinchin
        </p>

        <p className="single-gallery__text">
          Putting Contest (Ladies) – Norine Jones <br />
          Putting Contest (Men) – Shawn Thomas & Sandy Fraser
        </p>

        <p className="single-gallery__text">Low team: Chris Richardson, Justin Dunnett, Gerry Brown and Cinnamon</p>
      </div>
    ),
    subtitle: "2014 EACC Golf Tournament",
    photos: [
      {
        photo: "/images/PhotoGalleries/2014/2014-eacc-golf-tournament/2014-Golf-Tournament-01.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2014/2014-eacc-golf-tournament/2014-Golf-Tournament-02.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2014/2014-eacc-golf-tournament/2014-Golf-Tournament-03.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2014/2014-eacc-golf-tournament/2014-Golf-Tournament-04.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2014/2014-eacc-golf-tournament/2014-Golf-Tournament-05.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2014/2014-eacc-golf-tournament/2014-Golf-Tournament-06.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2014/2014-eacc-golf-tournament/2014-Golf-Tournament-07.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2014/2014-eacc-golf-tournament/2014-Golf-Tournament-08.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2014/2014-eacc-golf-tournament/2014-Golf-Tournament-09.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2014/2014-eacc-golf-tournament/2014-Golf-Tournament-10.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2014/2014-eacc-golf-tournament/2014-Golf-Tournament-11.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2014/2014-eacc-golf-tournament/2014-Golf-Tournament-12.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2014/2014-eacc-golf-tournament/2014-Golf-Tournament-13.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2014/2014-eacc-golf-tournament/2014-Golf-Tournament-14.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2014/2014-eacc-golf-tournament/2014-Golf-Tournament-15.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2014/2014-eacc-golf-tournament/2014-Golf-Tournament-16.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2014/2014-eacc-golf-tournament/2014-Golf-Tournament-17.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2014/2014-eacc-golf-tournament/2014-Golf-Tournament-18.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2014/2014-eacc-golf-tournament/2014-Golf-Tournament-19.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2014/2014-eacc-golf-tournament/2014-Golf-Tournament-20.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2014/2014-eacc-golf-tournament/2014-Golf-Tournament-21.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2014/2014-eacc-golf-tournament/2014-Golf-Tournament-22.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2014/2014-eacc-golf-tournament/2014-Golf-Tournament-23.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2014/2014-eacc-golf-tournament/2014-Golf-Tournament-24.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2014/2014-eacc-golf-tournament/2014-Golf-Tournament-25.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2014/2014-eacc-golf-tournament/2014-Golf-Tournament-26.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2014/2014-eacc-golf-tournament/2014-Golf-Tournament-27.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2014/2014-eacc-golf-tournament/2014-Golf-Tournament-28.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2014/2014-eacc-golf-tournament/2014-Golf-Tournament-29.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2014/2014-eacc-golf-tournament/2014-Golf-Tournament-30.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2014/2014-eacc-golf-tournament/2014-Golf-Tournament-31.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2014/2014-eacc-golf-tournament/2014-Golf-Tournament-32.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2014/2014-eacc-golf-tournament/2014-Golf-Tournament-33.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2014/2014-eacc-golf-tournament/2014-Golf-Tournament-34.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2014/2014-eacc-golf-tournament/2014-Golf-Tournament-35.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2014/2014-eacc-golf-tournament/2014-Golf-Tournament-36.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2014/2014-eacc-golf-tournament/2014-Golf-Tournament-37.jpg",
      },
    ],
  },
];
