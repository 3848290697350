import Hero from "../../../components/Hero/Hero";
import RadonNav from "../RadonNav/RadonNav";
import "./Radon101.scss";
import Nuclear from "../../../assets/radon/radon-nuclear.jpeg";

import questions from "../RadonQList/RadonQList";
import Question from "../Question/Question";

function Radon101() {
  return (
    <>
      <Hero title="Radon" subtitle="Home" />
      <RadonNav />
      <div className="radon">
        <div className="radon-101">
          <div>
            <ul>
              <li>
                <a className="radon__link" href="">
                  Introduction to Radon (dead link)
                </a>
              </li>
              <li>
                <a className="radon__link" href="">
                  Where to Test (dead link)
                </a>
              </li>
            </ul>
          </div>
          <img src={Nuclear} alt="Nuclear chart" />
        </div>

        <div>
          <h2 className="radon-101__header">EACC Radon FAQs</h2>

          <h3 className="radon-101__subheader">Radon Measurements – General</h3>

          {questions &&
            questions.map((question) => {
              return <Question question={question} />;
            })}
        </div>
      </div>
    </>
  );
}
export default Radon101;
