import Hero from "../../components/Hero/Hero";
import "./FaqPage.scss";
import QList from "./FaqList/FaqQList";
import Question from ".././RadonPages/Question/Question";

function FaqPage() {
  return (
    <>
      <Hero title="Frequently Asked Questions" />
      <div className="faq">
        {QList &&
          QList.map((question, i) => {
            return <Question key={i} question={question} />;
          })}
      </div>
    </>
  );
}
export default FaqPage;
