import ContactUs from "../ContactUs/ContactUs";
import Subscribe from "../Subscribe/Subscribe";
import twitter from "../../../assets/icons/twitter.svg";
import linkedin from "../../../assets/icons/linkedin.svg";
// import supercoollogo from "../../assets/icons/supercoollogo.gif";

import "./Footer.scss";
function Footer() {
  return (
    <>
      <Subscribe />
      <ContactUs />
      <div className="footer">
        <div className="footer__info">
          <div className="footer__icon-container footer__icon-container--mobile">
            <img
              className="footer__icon footer__icon--margin"
              src={twitter}
              alt=""
            />
            <img className="footer__icon" src={linkedin} alt="Twitter Icon" />
          </div>
          <p>
            © Environmental Abatement Council of Canada | All Rights Reserved |
            Designed by
            <span>
              <a
                className="footer__link"
                href="https://www.supercoolcode.com"
                target="_blank"
              >
                Supercool Code
              </a>
            </span>
          </p>
          <div className="footer__icon-container footer__icon-container--desktop">
            <img
              className="footer__icon footer__icon--margin"
              src={twitter}
              alt="Twitter Icon"
            />
            <img className="footer__icon" src={linkedin} alt="LinkedIn Icon" />
          </div>
        </div>
      </div>
    </>
  );
}
export default Footer;
