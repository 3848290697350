import "./News.scss";
import Calender from "../../../assets/icons/calender.png";
import Quote from "../../../assets/icons/quote.png";

function News() {
  return (
    <div className="news">
      <div className="news__card news__card--left">
        <img className="news__image" src={Calender} alt="Calender Icon" />
        {/* <h3 className="news__title">Upcoming Events</h3> */}
        <h3 className="news__title">Past Events</h3>
        <div className="news__text-wrapper">
          <p className="news__event-title">EACC Annual Golf Tournament</p>
          <p className="news__text news__text--padding">
            The Environmental Abatement Council of Canada hosted their annual
            golf tournament on September 18th, 2024 at Wooden Sticks Golf Club,
            Uxbridge, ON.
            <br />
            <br />
            All attendees received a pair of Adidas Golf Shoes, and they were
            treated to lunch before Golf and an amazing dinner after golf, and
            an opportunity to catch up with industry colleagues.
            <br />
            <br />
            {/* <span>Location:</span> Wooden Sticks Golf Club, Uxbridge */}
            Congratulations to all the winners and thanks to everyone who
            attended.
          </p>

          <a
            className="news__event-link"
            href={"/photos/2024-eacc-golf-tournament"}
          >
            See More
          </a>
        </div>
      </div>
      <div className="news__card">
        <img className="news__image" src={Quote} alt="Speech Bubble Icon" />
        <h3 className="news__title">News & Announcements</h3>
        <div className="news__text-wrapper">
          <p className="news__event-title">
            News: EACC Members Contribute to CSA Asbestos Report
          </p>
          <p className="news__text news__text--padding">
            EACC members provided input to researchers hired by the CSA Group to
            explore potential gaps and best practices in asbestos management in
            Canada and to determine if there is a need for the development of a
            national standard.
          </p>
          <p className="news__text">
            The CSA Asbestos report is now available on their{" "}
            <a
              className="news__event-link"
              href="https://www.csagroup.org/article/research/asbestos-management-in-canada-assessing-the-need-for-a-national-standard/"
            >
              website
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
}
export default News;
