import "./MobileDrugBurger.scss";

import React from "react";
import { Link } from "react-router-dom";

function MobileDrugBurger({ setDrugBurgerActive }) {
  return (
    <div className="mobile-drug">
      <div className="mobile-drug__dropdown">
        <ul className="mobile-drug__list">
          <Link
            to="/drug-opioid"
            onClick={() => setDrugBurgerActive(false)}
            className="mobile-drug__link"
          >
            <li className="mobile-drug__item">Drug/Opioid Home</li>
          </Link>
          <Link
            to="/drug-opioid-resources"
            onClick={() => setDrugBurgerActive(false)}
            className="mobile-drug__link"
          >
            <li className="mobile-drug__item">Drug/Opioid Resources</li>
          </Link>
        </ul>
      </div>
    </div>
  );
}

export default MobileDrugBurger;
