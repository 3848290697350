import React from "react";
import "../MembersPage/MembersPage.scss";
import Hero from "../../components/Hero/Hero";
import { Link } from "react-router-dom";

function MembersPage() {
  const listView = [
    {
      title: "Pre-Qualified Contractors (List View)",
      link: "/members/pre-qualified-contractors",
    },
    {
      title: "Pre-Qualified Consultants (List View)",
      link: "/members/consultants",
    },
    {
      title: "General Members (List View)",
      link: "/members/general-members",
    },
    {
      title: "Associates (List View)",
      link: "/members/associates",
    },
  ];
  const mapView = [
    {
      title: "Pre-Qualified Contractors (Map View)",
      link: "/members/pre-qualified-contractors/map",
    },
    {
      title: "Pre-Qualified Consultants (Map View)",
      link: "/members/consultants/map",
    },
    {
      title: "General Members (Map View)",
      link: "/members/general-members/map",
    },
    {
      title: "Associates (Map View)",
      link: "/members/members-associates/map",
    },
  ];

  return (
    <div className="members">
      <Hero title={"Members"} subtitle={"Find a Member"} smallSubtitle="true"></Hero>
      <div className="members__section">
        <h4 className="members__subheader">The EACC has members in the following four categories:</h4>
      </div>
      <div className="members__list">
        <div className="members__bin members__bin--listview">
          {listView.map((title, i) => {
            return (
              <Link key={i} to={title.link} className="members__link">
                <div className="members__item">
                  <h2 className="members__title">{title.title}</h2>
                </div>
              </Link>
            );
          })}
        </div>
        <div className="members__bin members__bin--mapview">
          {mapView.map((title) => {
            return (
              <Link to={title.link} className="members__link">
                <div className="members__item">
                  <h2 className="members__title">{title.title}</h2>
                </div>{" "}
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default MembersPage;
