export default [
  {
    info: (
      <div className="single-gallery__info">
        <p className="single-gallery__text">May 7, 2014</p>
        <p className="single-gallery__text">
          Ten Restaurant & Wine Bar <br />
          139 Lakeshore Rd. E.
          <br />
          Port Credit, ON L5G 1E5
        </p>
      </div>
    ),
    subtitle: "2014 Women’s Social",
    photos: [
      {
        photo: "/images/PhotoGalleries/2014/2014-womens-inaugural-social/2014-Womens-Social-01.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2014/2014-womens-inaugural-social/2014-Womens-Social-02.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2014/2014-womens-inaugural-social/2014-Womens-Social-03.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2014/2014-womens-inaugural-social/2014-Womens-Social-04.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2014/2014-womens-inaugural-social/2014-Womens-Social-05.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2014/2014-womens-inaugural-social/2014-Womens-Social-06.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2014/2014-womens-inaugural-social/2014-Womens-Social-07.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2014/2014-womens-inaugural-social/2014-Womens-Social-08.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2014/2014-womens-inaugural-social/2014-Womens-Social-09.jpg",
      },
    ],
  },
];
