import "./BoardMember.scss";
function BoardMember({ boardMember }) {
  const { title, name, company, address, phone, fax, email, image } = boardMember;
  return (
    <div className="member">
      {title && <h2 className="member__title">{title}</h2>}
      <div className="member__info-container">
        <img className="member__image" src={image} alt={title} loading="lazy" />
        <div>
          <p className="member__text">{name}</p>
          <p className="member__text">{company}</p>
          <p className="member__text">{address}</p>
          <p className="member__text">{`Phone: ${phone} ${fax ? `| ${fax}` : ""}`}</p>
          <p className="member__text">{`Email: ${email}`}</p>
        </div>
      </div>
    </div>
  );
}
export default BoardMember;
