import "./RadonMember.scss";

function RadonMember({ member }) {
  return (
    <div className="radon-member">
      <img
        className="radon-member__image"
        src={member.image}
        alt={`${member.name} Logo`}
      />
      <div className="radon-member__info">
        <p className="radon-member__text">{member.name}</p>
        <p className="radon-member__text">{member.address}</p>
        <p className="radon-member__text">{member.city}</p>
        <a
          className="radon-member__link"
          href={`http://${member.website}`}
          target="_blank"
          rel="noreferrer"
        >
          {member.website}
        </a>
        {member.phone && <p className="radon-member__text">{member.phone}</p>}
        {member.contacts.map((contact) => (
          <>
            {contact.phone && (
              <p className="radon-member__text">{contact.phone}</p>
            )}
            <p className="radon-member__text">{contact.name}</p>
            <a href={`mailto:${contact.email}`} className="radon-member__link">
              {contact.email}
            </a>
          </>
        ))}
      </div>
    </div>
  );
}
export default RadonMember;
