import Hero from "../../components/Hero/Hero";
import "./BoardDatesPage.scss";
function BoardDatesPage() {
  return (
    <>
      <Hero title="Board Meeting Dates" />
      <div className="board-dates">
        <p className="board-dates__text">
          {`EACC members are invited to attend meetings of the EACC Board of Directors. As space is limited, it will be on a first come, first served
          basis. Please notify Jodi White at `}
          <a className="board-dates__link" href="mailto:jwhite@tcaconnect.com">
            jwhite@tcaconnect.com
          </a>
          {` if you wish to attend any meeting.`}
        </p>

        <p className="board-dates__text board-dates__text--padding">The meeting dates are:</p>

        <ul>
          <li className="board-dates__list-item">Feb 6, 2024, from 9:30 - 11: 30 am by Zoom and at TCA Offices</li>
          <li className="board-dates__list-item">March 28, 2024, from 9:30 - 11: 30 am by Zoom and at TCA Offices</li>
          <li className="board-dates__list-item">May 16, 2024, from 9:30 - 11: 30 am by Zoom and at TCA Offices</li>
          <li className="board-dates__list-item">July 25, 2024, from 9:30 - 11: 30 am by Zoom and at TCA Offices</li>
          <li className="board-dates__list-item">September 26, 2024 from 9:30 - 11:30 am by Zoom and at TCA Offices</li>
          <li className="board-dates__list-item">November 28, 2024 from 9:30 - 11:30 am by Zoom and at TCA Offices</li>
        </ul>
      </div>
    </>
  );
}
export default BoardDatesPage;
