import React from "react";
import { useState } from "react";
import "../MembersComponent/MembersComponent.scss";
import expandProfileButton from "../../assets/icons/plus-circle.svg";
import closeProfileButton from "../../assets/icons/minus-circle.svg";

function MembersComponentListView({ data }) {
  // const [toggle, setToggle] = useState(false);
  const [toggle, setToggle] = useState(Array(data.length).fill(false));

  const updateToggle = (id) => {
    const newToggle = toggle.map((item, i) => {
      if (i === id) {
        return !item;
      } else {
        return item;
      }
    });
    setToggle(newToggle);
  };

  return (
    <div className="members-component">
      {data
        .sort((a, b) => {
          return a.contacts[0].company.localeCompare(b.contacts[0].company);
        })
        .map((member, i) => {
          return (
            <div
              key={i}
              className="members-component__section"
              onClick={() => {
                if (member.profile.length > 0 || member.logo || member.custom) {
                  updateToggle(i);
                }
              }}
            >
              <div className="members-component__bin">
                <h3 className="members-component__company">
                  {member.contacts[0].company}
                </h3>
                <div className="members-component__card">
                  <div className="members-component__topbox">
                    {member.contacts.map((contact, j) => {
                      return (
                        <div
                          key={j}
                          className="members-component__text-wrapper"
                        >
                          <p className="members-component__text ">{`${
                            contact.name
                          } ${contact.name && "|"} ${contact.address}, ${
                            contact.city
                          }, ${contact.province} ${contact.postal}`}</p>
                          {/* <p className="members-component__text ">{contact.name}</p>
                        <p className="members-component__text">{contact.address},</p>
                        <p className="members-component__text">{contact.city},</p>
                        <p className="members-component__text">{contact.province},</p>
                        <p className="members-component__text">{contact.postal}</p> */}

                          <div className="members-component__container">
                            {(contact.phone || contact.fax) && (
                              <p className="members-component__text ">{`${
                                contact.phone && `Phone: ${contact.phone} `
                              }${contact.fax && `| Fax: ${contact.fax}`}`}</p>
                            )}

                            {/* {contact.phone && <p className="members-component__text ">Phone: {contact.phone}</p>}
                        {contact.fax && <p className="members-component__text">Fax: {contact.fax}</p>} */}

                            <div className="members-component__web">
                              {contact.email && (
                                <p className="members-component__text">
                                  {`${contact.email ? "Email: " : ""}`}
                                  <a
                                    className="members-component__weblink"
                                    href={`mailto:${contact.email}`}
                                  >
                                    {`${contact.email} `}
                                  </a>
                                </p>
                              )}

                              <p className="members-component__text">
                                {`${contact.website && "Web: "}`}
                                <a
                                  className="members-component__weblink"
                                  href={`http://${contact.website}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {contact.website}
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="members-component__additional">
                    <p className="members-component__text members-component__text--padding">
                      {member.services
                        ? `Residential Services: ${member.services}`
                        : ""}
                    </p>

                    {(member.logo || member.profile.length > 0) &&
                      !toggle[i] && (
                        <div className="members-component__expand">
                          <h4 className="members-component__subheader">
                            Profile
                          </h4>
                          <img
                            src={expandProfileButton}
                            className="members-component__button"
                            alt="expand section button"
                          ></img>
                        </div>
                      )}

                    {(member.logo || member.profile) && toggle[i] && (
                      <div className="members-component__expand members-component__expand--expanded">
                        <div className="members-component__profile-wrapper">
                          <h4 className="members-component__subheader">
                            Profile
                          </h4>
                          <img
                            onClick={() => updateToggle(i)}
                            src={closeProfileButton}
                            className="members-component__button"
                            alt="expand section button"
                          ></img>
                        </div>
                        <div className="members-component__expanded">
                          <img
                            className="members-component__logo"
                            src={member.logo}
                            alt="Compoany Logo Here"
                          ></img>
                          {member.profile.map((text, k) => {
                            if (k !== member.profile.length - 1) {
                              return (
                                <p
                                  key={k}
                                  className="members-component__text members-component__text--padding"
                                >
                                  {text}
                                </p>
                              );
                            }

                            return (
                              <p key={i} className="members-component__text">
                                {text}
                              </p>
                            );
                          })}
                          {member.custom && member.custom}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default MembersComponentListView;
