export default [
  {
    info: (
      <div className="single-gallery__info">
        <p className="single-gallery__text">June 1, 2016</p>
        <p className="single-gallery__text">
          Ottawa Lowertown Brewery <br />
          73 York St., In The Byward Market
          <br />
          Ottawa ON K1N 5T2
        </p>
      </div>
    ),
    subtitle: "2016 EACC Pub Night Ottawa",
    photos: [
      {
        photo: "/images/PhotoGalleries/2016/2016-pub-night/2016-Pub-Night-01.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2016/2016-pub-night/2016-Pub-Night-02.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2016/2016-pub-night/2016-Pub-Night-03.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2016/2016-pub-night/2016-Pub-Night-05.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2016/2016-pub-night/2016-Pub-Night-04.jpg",
      },
    ],
  },
];
