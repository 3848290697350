const Image =
  "/images/PhotoGalleries/2024/2024-eacc-golf-tournament/EACC Golf Sponsors.png";

export default [
  {
    info: (
      <div className="single-gallery__info">
        <p className="single-gallery__text">
          The Environmental Abatement Council of Canada hosted their annual golf
          tournament on September 18th, 2024 at Wooden Sticks Golf Club,
          Uxbridge, ON.
        </p>

        <p className="single-gallery__text">
          All attendees received a pair of Adidas Golf Shoes, and they were
          treated to lunch before golf and an amazing dinner after golf, and an
          opportunity to catch up with industry colleagues.
        </p>

        <p className="single-gallery__text">
          Congratulations to all the winners and thanks to everyone who
          attended.
        </p>

        <p className="single-gallery__text">Special thanks to our sponsors:</p>

        <img
          className="single-gallery__sponsor-image--large"
          src={Image}
          alt="Golf Day Sponsors"
        />
      </div>
    ),
    subtitle: "2024 EACC Golf Tournament",
    photos: [
      {
        photo: "/images/PhotoGalleries/2024/2024-eacc-golf-tournament/1.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2024/2024-eacc-golf-tournament/2.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2024/2024-eacc-golf-tournament/3.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2024/2024-eacc-golf-tournament/4.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2024/2024-eacc-golf-tournament/5.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2024/2024-eacc-golf-tournament/6.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2024/2024-eacc-golf-tournament/7.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2024/2024-eacc-golf-tournament/8.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2024/2024-eacc-golf-tournament/9.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2024/2024-eacc-golf-tournament/10.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2024/2024-eacc-golf-tournament/11.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2024/2024-eacc-golf-tournament/12.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2024/2024-eacc-golf-tournament/13.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2024/2024-eacc-golf-tournament/14.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2024/2024-eacc-golf-tournament/15.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2024/2024-eacc-golf-tournament/16.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2024/2024-eacc-golf-tournament/17.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2024/2024-eacc-golf-tournament/18.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2024/2024-eacc-golf-tournament/19.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2024/2024-eacc-golf-tournament/20.jpg",
      },
    ],
  },
];
