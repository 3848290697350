export default [
  {
    info: (
      <div className="single-gallery__info">
        <p className="single-gallery__text">
          EACC’s annual Golf Tournament at Wooden Sticks on September 13th was a huge success. Weather was perfect, course was ideal and the company
          was the best.
        </p>
        <p className="single-gallery__text">Thank you to our sponsors:</p>

        <ul className="single-gallery__list">
          <li className="single-gallery__list-item">Cart Sponsor: Hazmasters</li>
          <li className="single-gallery__list-item">Lunch Sponsors: H&S Specialties and McIntosh Perry</li>
          <li className="single-gallery__list-item">Dinner Wine Sponsors: Stantec Consulting and ECOH</li>
          <li className="single-gallery__list-item">Putting Contest Sponsor: FCA Insurance</li>
          <li className="single-gallery__list-item">
            Hole Sponsors:
            <ul className="single-gallery__list single-gallery__list--secondary">
              <li className="single-gallery__list-item">Abbot Environmental Ltd.</li>
              <li className="single-gallery__list-item">Caliber Environmental Construction Services</li>
              <li className="single-gallery__list-item">ECOH</li>
              <li className="single-gallery__list-item">EMSL Canada, Inc.</li>
              <li className="single-gallery__list-item">Envirosafe Inc.</li>
              <li className="single-gallery__list-item">I & I Construction Services Limited</li>
              <li className="single-gallery__list-item">Inscan Contractors Inc.</li>
              <li className="single-gallery__list-item">McGowan Insulations Ltd.</li>
              <li className="single-gallery__list-item">Ontario Association of Demolition Contractors</li>
              <li className="single-gallery__list-item">Paracel Labratories</li>
            </ul>
          </li>
        </ul>

        <p className="single-gallery__text">The winner of FCA Insurance Putting Contest was Catherine Mills.</p>

        <p className="single-gallery__text">The Best Team was: Martin Ling, Will Madden-Macavelia, Rob Robinson and Suzanne Wilde</p>

        <p className="single-gallery__text">
          Longest Drive (men): Josh Rumball <br />
          Longest Drive (women): Jamie Ferrante
          <br />
          Closest to the Pin (men): Dan Church
          <br />
          Closet to the Pin (women): Jamie Ferrante
          <br />
          Beat the Pro Winner: Adam Trimm
        </p>

        <p className="single-gallery__text">See you next year!</p>
      </div>
    ),
    subtitle: "2017 EACC Golf Tournament",
    photos: [
      {
        photo: "/images/PhotoGalleries/2017/2017-eacc-golf-tournament/2017-Golf-Tournament-01.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2017/2017-eacc-golf-tournament/2017-Golf-Tournament-02.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2017/2017-eacc-golf-tournament/2017-Golf-Tournament-03.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2017/2017-eacc-golf-tournament/2017-Golf-Tournament-04.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2017/2017-eacc-golf-tournament/2017-Golf-Tournament-05.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2017/2017-eacc-golf-tournament/2017-Golf-Tournament-06.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2017/2017-eacc-golf-tournament/2017-Golf-Tournament-07.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2017/2017-eacc-golf-tournament/2017-Golf-Tournament-08.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2017/2017-eacc-golf-tournament/2017-Golf-Tournament-09.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2017/2017-eacc-golf-tournament/2017-Golf-Tournament-10.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2017/2017-eacc-golf-tournament/2017-Golf-Tournament-11.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2017/2017-eacc-golf-tournament/2017-Golf-Tournament-12.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2017/2017-eacc-golf-tournament/2017-Golf-Tournament-13.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2017/2017-eacc-golf-tournament/2017-Golf-Tournament-14.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2017/2017-eacc-golf-tournament/2017-Golf-Tournament-15.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2017/2017-eacc-golf-tournament/2017-Golf-Tournament-16.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2017/2017-eacc-golf-tournament/2017-Golf-Tournament-17.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2017/2017-eacc-golf-tournament/2017-Golf-Tournament-18.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2017/2017-eacc-golf-tournament/2017-Golf-Tournament-19.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2017/2017-eacc-golf-tournament/2017-Golf-Tournament-20.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2017/2017-eacc-golf-tournament/2017-Golf-Tournament-21.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2017/2017-eacc-golf-tournament/2017-Golf-Tournament-22.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2017/2017-eacc-golf-tournament/2017-Golf-Tournament-23.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2017/2017-eacc-golf-tournament/2017-Golf-Tournament-24.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2017/2017-eacc-golf-tournament/2017-Golf-Tournament-25.jpg",
      },
    ],
  },
];
