import { useEffect, useRef, useState } from "react";
import "./Question.scss";
import expandProfileButton from "../../../assets/icons/plus-circle.svg";
import closeProfileButton from "../../../assets/icons/minus-circle.svg";

function Question({ question }) {
  const [active, setActive] = useState(false);
  const questionRef = useRef();

  return (
    <>
      {question.title && question.title}
      <div
        className={`question ${active ? "question--color" : ""} `}
        onClick={() => {
          if (active) {
            questionRef.current.classList.remove("question--expanded");
            questionRef.current.classList.add("question--close");
            setTimeout(() => {
              setActive(false);
            }, 1000);
          } else {
            questionRef.current.classList.remove("question--close");
            questionRef.current.classList.add("question--expanded");
            setActive(true);
          }
        }}
      >
        <div className="question__wrapper">
          <div className={active && "question__color"}>{question.question}</div>
          <img
            className="question__icon"
            src={active ? closeProfileButton : expandProfileButton}
            alt={active ? "Close Icon" : "Expand Icon"}
          />
        </div>
        <div ref={questionRef}>{active && question.answer}</div>
      </div>
    </>
  );
}

export default Question;
