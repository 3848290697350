import Hero from "../../components/Hero/Hero";
import RadonNav from "./RadonNav/RadonNav";
import "./RadonPage.scss";

import House from "../../assets/radon/radon-house.png";
import Cancer from "../../assets/radon/radon-cancer-risk.png";

function RadonPage() {
  return (
    <>
      <Hero title="Radon" subtitle="Home" />
      <RadonNav />
      <div className="radon">
        <ul className="radon__list">
          <li className="radon__list-item">
            Radon is radiation which is the 2nd leading cause of lung cancer and
            kills over 3,000 Canadians each year according to the
            <a
              className="radon__link"
              href="https://www.canada.ca/en/health-canada/services/health-risks-safety/radiation/radon.html"
            >
              Government of Canada
            </a>
          </li>
          <li className="radon__list-item">
            It is present in virtually all buildings:
          </li>

          <img className="radon__image" src={House} alt="House image" />

          <li className="radon__list-item">
            <a
              className="radon__link"
              href="https://cancer.ca/en/cancer-information/reduce-your-risk/know-your-environment/radon"
              target="_blank"
              rel="noreferrer"
            >
              The Canadian Cancer Society
            </a>
            {` estimates 16% of lung cancer deaths are related to being exposed to radon in the home.`}
          </li>

          <li className="radon__list-item">
            188,000 Canadians are occupationally exposed to radon.
          </li>

          <li className="radon__list-item">
            {` According to `}
            <a
              className="radon__link"
              href="https://www.carexcanada.ca/special-topics/radon-in-buildings/"
              target="_blank"
              rel="noreferrer"
            >
              CAREX Canada
            </a>
            {`, their estimates use “lifetime excess cancer risk as an indicator of Canadians' exposure to known or suspected carcinogens in the
            environment. Risk estimates for indoor air carcinogens show that radon gas is the highest priority exposure in Canadian settings.”`}
          </li>

          <img className="radon__image" src={Cancer} alt="Cancer Chart" />

          <li className="radon__list-item">
            According to the
            <a
              className="radon__link"
              href="https://www.ccohs.ca/oshanswers/phys_agents/radon.html"
              target="_blank"
              rel="noreferrer"
            >
              Canadian Centre for Occupational Health and Safety
            </a>
            , the only way to know for sure if your home or workplace has levels
            of radon higher than the guidelines is to conduct testing in each
            home or workplace of concern regardless of location.
          </li>
        </ul>
      </div>
    </>
  );
}
export default RadonPage;
