export default [
  {
    info: (
      <div className="single-gallery__info">
        <p className="single-gallery__text">November 17, 2010</p>
        <p className="single-gallery__text">
          On Wednesday November 17, 2010 EACC hosted its first Women of EACC Pub Night at Marlowe’s in Richmond Hill. More than 30 women attended from
          member firms, and as well as some from companies that are interested in joining EACC. It was a fun night of networking, reconnecting with
          old colleagues and meeting new people in the environmental abatement industry.
        </p>

        <p className="single-gallery__text">
          The atmosphere of the night was friendly and supportive, and the general consensus among the women present was “why didn’t do this before?”
          Involvement in the industry we work in is important, and this event recognized the need for getting as many people involved as possible.
          Hopefully we can keep the momentum going and hold more events to encourage the involvement of all EACC members, including the females in the
          industry.
        </p>

        <p className="single-gallery__text">
          Thank you to Kristine White of Sporometrics for spearheading this event along with Jamie Ferrante of Ferro Canada, and of course Mary
          Thornburn of EACC.
        </p>

        <p className="single-gallery__text">
          If you would like to ensure you are on the distribution list for future Women of EACC events please email Jodi White at
          <a className="single-gallery__link" href="mailto:jwhite@tcaconnect.com">
            jwhite@tcaconnect.com
          </a>
          .
        </p>
      </div>
    ),
    subtitle: "2010 Women’s Social",
    photos: [
      {
        photo: "/images/PhotoGalleries/2010/2010-womens-inaugural-social/2010-Womens-Social-01.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2010/2010-womens-inaugural-social/2010-Womens-Social-02.jpg",
      },
    ],
  },
];
