import { Link, useLocation } from "react-router-dom";
import "./Hero.scss";

function Hero({ title, subtitle }) {
  let location = useLocation();

  const isHomePage = () => {
    if (location.pathname === "/") {
      return true;
    }
    return false;
  };

  return (
    <>
      <div className={`hero ${isHomePage() && "hero--home"}`}>
        <div
          className={`hero__overlay ${
            isHomePage() && "hero__overlay--homepage"
          }`}
        >
          <div className="hero__text-container">
            <h2 className="hero__title">{title}</h2>

            {subtitle && <h3 className={`hero__subheader`}>{subtitle}</h3>}

            {isHomePage() && (
              <>
                <h2 className="hero__title hero__title--welcome">
                  Welcome to the EACC
                </h2>
                <p className="hero__subheader ">
                  The Environmental Abatement Council of Canada was originally
                  formed as a contractor based organization and has now grown to
                  represent contractors, consultants, engineers, suppliers,
                  government officials and other industry stakeholders for the
                  betterment of the abatement and hazardous materials industry.
                </p>
              </>
            )}
          </div>
          {isHomePage() && (
            <Link className="hero__button" to="/about">
              WHO WE ARE
            </Link>
          )}
        </div>
      </div>
      {isHomePage() && (
        <div className="hero__members">
          <div className="hero__button-wrapper">
            <Link
              to="/join"
              className="hero__member-button hero__member-button--become"
            >
              BECOME A MEMBER
            </Link>
            <Link
              to="/members"
              className="hero__member-button hero__member-button--find"
            >
              FIND A MEMBER
            </Link>
          </div>
        </div>
      )}
    </>
  );
}
export default Hero;
