import Hero from "../../components/Hero/Hero";
import "./MapPage.scss";
import Marker from "../../assets/red-dot.png";
import Map from "./Map/Map";
import { useEffect, useState } from "react";
import PreQualifiedContractorsList from "../../PreQualifiedContractors";
import ConsultantsList from "../../Consultants";
import AssociatesList from "../../Associates";
import GeneralList from "../../GeneralMembers";
import { useParams } from "react-router-dom";

function MapPage() {
  const [selected, setSelected] = useState(null);
  let { page } = useParams();

  const [membersList, setMembersList] = useState(null);
  const [listName, setListName] = useState(null);

  useEffect(() => {
    if (page === "pre-qualified-contractors") {
      setMembersList(PreQualifiedContractorsList);
      setListName("Pre-Qualified Contractors");
    }

    if (page === "consultants") {
      setMembersList(ConsultantsList);
      setListName("Consultants");
    }

    if (page === "members-associates") {
      setMembersList(AssociatesList);
      setListName("Associates Contractors");
    }

    if (page === "general-members") {
      setMembersList(GeneralList);
      setListName("General Members");
    }
  }, []);

  return (
    <>
      {listName && membersList && (
        <>
          <Hero title="Members Map" subtitle={listName} />

          <div className="map-page">
            <div className="map-page__divider"></div>
            <h1 className="map-page__header">{listName}</h1>
            <div className="map-page__container">
              <div className="map-page__members-list map-page__members-list--tablet">
                {membersList
                  .sort((a, b) => {
                    return a.contacts[0].company.localeCompare(
                      b.contacts[0].company
                    );
                  })
                  .map((member, i) => {
                    return member.contacts.map((contact, j) => {
                      if (member.contacts.length > 1) {
                        return (
                          <div
                            className="map-page__member"
                            key={`${i} ${j}`}
                            onClick={() => {
                              setSelected(contact);
                            }}
                          >
                            <img
                              className="map-page__image"
                              src={Marker}
                              alt=""
                            />
                            <p className="map-page__company">
                              {`${contact.company} (${j + 1})`}{" "}
                            </p>
                          </div>
                        );
                      }

                      return (
                        <div
                          className="map-page__member"
                          key={i}
                          onClick={() => {
                            setSelected(contact);
                          }}
                        >
                          <img
                            className="map-page__image"
                            src={Marker}
                            alt=""
                          />
                          <p className="map-page__company">{contact.company}</p>
                        </div>
                      );
                    });
                  })}
              </div>
              <Map
                membersList={membersList}
                selected={selected}
                setSelected={setSelected}
              />
              <div className="map-page__members-list map-page__members-list--mobile">
                {membersList.map((member, i) => {
                  return member.contacts.map((contact, j) => {
                    if (member.contacts.length > 1) {
                      return (
                        <div
                          className="map-page__member"
                          key={`${i} ${j}`}
                          onClick={() => {
                            setSelected(contact);
                          }}
                        >
                          <img
                            className="map-page__image"
                            src={Marker}
                            alt=""
                          />
                          <p className="map-page__company">
                            {`${contact.company} (${j + 1})`}{" "}
                          </p>
                        </div>
                      );
                    }

                    return (
                      <div
                        className="map-page__member"
                        key={i}
                        onClick={() => {
                          setSelected(contact);
                        }}
                      >
                        <img
                          className="map-page__image"
                          src={Marker}
                          alt="Map Pin Icon"
                        />
                        <p className="map-page__company">{contact.company}</p>
                      </div>
                    );
                  });
                })}
              </div>
            </div>{" "}
            <div className="map-page__divider map-page__divider--bottom"></div>
          </div>
        </>
      )}
    </>
  );
}
export default MapPage;
