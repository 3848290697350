export default [
  {
    subtitle: "2023 EACC Annual General Meeting",
    info: (
      <div className="single-gallery__info">
        <p className="single-gallery__text">
          On May 25, 2023 the Environmental Abatement Council of Canada (EACC)
          held its Annual General Meeting.
        </p>

        <p className="single-gallery__text">
          A special thanks to our sponsor EMSL.
        </p>
        <img
          className="single-gallery__sponsor-image"
          src="/images/PhotoGalleries/2023/2023-eacc-agm/EMSL.png"
          alt="EMSL Logo"
        />
      </div>
    ),
    photos: [
      {
        photo: "/images/PhotoGalleries/2023/2023-eacc-agm/1.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2023/2023-eacc-agm/2.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2023/2023-eacc-agm/3.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2023/2023-eacc-agm/4.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2023/2023-eacc-agm/5.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2023/2023-eacc-agm/6.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2023/2023-eacc-agm/7.jpg",
      },
    ],
  },
];
