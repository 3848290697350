export default [
  {
    info: (
      <div className="single-gallery__info">
        <p className="single-gallery__text">September 14, 2012</p>
        <p className="single-gallery__text">
          144 persons attended this year’s annual tournament at Wooden Sticks Golf Club in Uxbridge. Members enjoyed great weather for golf and the
          sunshine continued throughout the day. Those who attended started off with a BBQ lunch, followed by golf. Cocktails and dinner rounded out
          the day.
        </p>

        <p className="single-gallery__text">
          This year’s attendees received a pair of Foot Joy golf sandals and a sleeve of golf balls, compliments of EACC and a hat, compliments of
          Wooden Sticks.
        </p>

        <p className="single-gallery__text">Thanks are extended to Ian Henderson and Steve Fulford for their assistance in organizing the event.</p>

        <p className="single-gallery__text">Thanks also go out to this year’s sponsors:</p>

        <p className="single-gallery__text single-gallery__text--bold">
          Diamond Sponsor – Hole-in-One: Biggs & Narciso Construction Services Inc.
          <br />
          Golf Cart Sponsor: Ferro Canada Inc.
          <br />
          Platinum Sponsor- Grand Prize: Hazmasters
          <br />
          Gold Sponsors – Lunch: H & S Specialties and Safety Express Ltd.
          <br />
          Silver Sponsors – Dinner Wine: ENSPEC and Envirosafe Inc.
          <br />
          Silver Sponsor – Putting Contest: Sporometrics
        </p>

        <p className="single-gallery__text single-gallery__text--bold">
          Bronze Sponsors – Hole Sponsors:
          <br />
          Alliance Environmental & Abatement Contractors Inc. <br />
          Caliber Environmental Construction Services Inc.
          <br />
          ECOH Management
          <br />
          Envirosafe Inc.
          <br />
          GB Environmental
          <br />
          Hazmasters
          <br />
          Krytiuk Contracting Inc.
          <br />
          MTE Consultants Inc.
          <br />
          National Waste and Recycling
          <br />
          Pinchin Environmental Ltd.
          <br />
          Quantum Murray LP
          <br />
          Safety Express Ltd.
          <br />
          Tri-Phase Environmental Inc.
        </p>

        <p className="single-gallery__text">This year’s silent auction raised $1,470 and a donation will be made to a charity of EACC’s choice.</p>
      </div>
    ),
    subtitle: "2012 EACC Golf Tournament",
    photos: [
      {
        photo: "/images/PhotoGalleries/2012/2012-eacc-golf-tournament/2012-Golf-Tournament-01.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2012/2012-eacc-golf-tournament/2012-Golf-Tournament-02.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2012/2012-eacc-golf-tournament/2012-Golf-Tournament-03.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2012/2012-eacc-golf-tournament/2012-Golf-Tournament-04.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2012/2012-eacc-golf-tournament/2012-Golf-Tournament-05.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2012/2012-eacc-golf-tournament/2012-Golf-Tournament-06.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2012/2012-eacc-golf-tournament/2012-Golf-Tournament-07.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2012/2012-eacc-golf-tournament/2012-Golf-Tournament-08.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2012/2012-eacc-golf-tournament/2012-Golf-Tournament-09.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2012/2012-eacc-golf-tournament/2012-Golf-Tournament-10.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2012/2012-eacc-golf-tournament/2012-Golf-Tournament-11.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2012/2012-eacc-golf-tournament/2012-Golf-Tournament-12.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2012/2012-eacc-golf-tournament/2012-Golf-Tournament-13.jpg",
      },
    ],
  },
];
