import React from "react";
import Code from "../../../assets/pdfs/Code-of-Ethics-April-2021.pdf";
import committees from "../../../committees.json";
export default [
  {
    id: 1,
    question: <h4 className="question__title">1. What qualifications does a firm need to become a member of EACC?</h4>,
    answer: (
      <div className="question__answer-wrapper">
        <p className="question__answer">EACC has 4 Membership Categories;</p>
        <p className="question__answer faq__text--list">
          Pre-Qualified Contractors
          <br /> Consultants
          <br /> General Members <br />
          Associates
        </p>
        <p className="question__text">Please view our Membership Application and the qualifications required per Member category.</p>
      </div>
    ),
  },

  {
    id: 2,
    question: (
      <h4 className="question__title">
        2. Who audits and verifies the submitted qualifications and determines if the statement of qualifications is factual and that it truly meets
        the minimum requirements?
      </h4>
    ),
    answer: (
      <div className="question__answer-wrapper">
        <p className="question__answer">
          All Membership Applications are audited and verified by the Membership Committee, which consist of members of the EACC Board of Directors.
        </p>
      </div>
    ),
  },

  {
    id: 3,
    question: (
      <h4 className="question__title">
        3. Does EACC have an ethical standard that it holds its members to? If it does, is there a discipline process for members who violate that
        standard?
      </h4>
    ),
    answer: (
      <div className="question__answer-wrapper">
        <p className="question__answer">
          The EACC Board of Directors may suspend, cancel membership of, censure, or reprimand any member who has been guilty of conduct prejudicial
          to the objects of the Association, breach of the Constitution or By-laws. The Board of Directors shall not take any such action until after
          a complaint in writing has been filed with the Secretary and a copy forwarded to the member, and the member has been given an opportunity of
          submitting evidence in making representation in his defence in such manner as the Board of Directors may prescribe.
        </p>

        <p>
          {`> `}
          <a className="question__link" href={Code}>
            EACC's Code of Ethics
          </a>
        </p>
      </div>
    ),
  },

  {
    id: 4,
    question: (
      <h4 className="question__title">4. Once a firm becomes a member, does it need to verify that it still qualifies to remain a member?</h4>
    ),
    answer: (
      <div className="question__answer-wrapper">
        <p className="question__answer">
          The Status of a member company is reviewed by the Board of Directors, on an as needed basis, to ensure compliance with EACC’s membership
          requirements are being maintained.
        </p>
      </div>
    ),
  },

  {
    id: 5,
    question: (
      <h4 className="question__title">
        5. When EACC prepares a Guideline document, is it peer reviewed by another technical authority not associated by EACC?
      </h4>
    ),
    answer: (
      <div className="question__answer-wrapper">
        <p className="question__answer">
          In most instances EACC Guidelines are prepared by industry experts and a committee of experts with differing experiences, adding to the
          diversity of review from many different perspectives. Most of the committees have members from peer organizations such as EACC and bring
          that experience to the review process.
        </p>
      </div>
    ),
  },

  {
    id: 6,
    question: <h4 className="question__title">6. What committees does EACC currently have? What issues are they addressing?</h4>,
    answer: (
      <div className="question__answer-wrapper">
        <>
          {committees &&
            committees.map((committee, i) => {
              return (
                <>
                  <p>
                    <strong>{committee.name}</strong>
                  </p>
                  {committee.people.map((person, j) => {
                    return (
                      <p key={j} className="question__answer">
                        {person.name}
                        <br />
                        <a className="question__link" href={`mailto:${person.email}`}>
                          {person.email}
                        </a>
                      </p>
                    );
                  })}
                </>
              );
            })}
        </>
      </div>
    ),
  },

  {
    id: 7,
    question: (
      <h4 className="question__title">
        7. Is it possible to join any committee as an observer, where the member does not have to spend time in contributing outside of attending
        meetings?
      </h4>
    ),
    answer: (
      <div className="question__answer-wrapper">
        <p className="question__answer">
          Members may join any committee as an observer, but this is under the discretion of the Committee Chair and is based on the number of
          available committee member spaces open. Please contact the specific committee chair for more information.
        </p>
      </div>
    ),
  },

  {
    id: 8,
    question: (
      <h4 className="question__title">
        8. Are there any consultants or specifiers who either require membership of EACC or look favorably upon membership in EACC as part of any bid
        submissions?
      </h4>
    ),
    answer: (
      <div className="question__answer-wrapper">
        <p className="question__answer">
          There are a number of consultants and/or specifiers who require proof of membership (in good standing) with EACC or look favorably upon EACC
          membership.
        </p>
      </div>
    ),
  },
  {
    id: 9,
    question: (
      <h4 className="question__title">
        9. Does my main business have to be hazardous materials abatement to join or is it open to others (demolition, specialty or general
        contractors)?
      </h4>
    ),
    answer: (
      <div className="question__answer-wrapper">
        <p className="question__answer">
          EACC encourages all persons or companies involved in environmental abatement, whether as a contractor, consultant, general contractor or
          representative of a building owner, school board or building operator, to become involved in the industry through membership in EACC.
        </p>
      </div>
    ),
  },
  {
    id: 10,
    question: <h4 className="question__title">10. Can EACC provide project specific guidance or recommendations?</h4>,
    answer: (
      <div className="question__answer-wrapper">
        <p className="question__answer">
          EACC is not able to provide project-specific guidance or recommendations, such as work procedures for tender purposes. EACC strongly
          encourages the Building/Project Owner to retain the services of an EACC Consultant Member to provide project-specific Environmental
          Engineering and Consulting services.
        </p>
      </div>
    ),
  },
];
