export default [
  {
    subtitle: "2015 EACC Annual General Meeting and Conference",
    photos: [
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-agm/2015-AGM-01.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-agm/2015-AGM-02.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-agm/2015-AGM-03.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-agm/2015-AGM-04.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-agm/2015-AGM-05.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-agm/2015-AGM-06.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-agm/2015-AGM-07.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-agm/2015-AGM-08.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-agm/2015-AGM-09.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-agm/2015-AGM-10.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-agm/2015-AGM-11.jpg",
      },
      {
        photo: "/images/PhotoGalleries/2015/2015-eacc-agm/2015-AGM-12.jpg",
      },
    ],
  },
];
